// Fonts
$f_Exo: 'Exo20', sans-serif;

/*Exo20-Regular*/
@font-face {
    font-family: 'Exo20';
    src: url('../fonts/Exo20/Exo20-Regular/Exo20-Regular.eot'),
    url('../fonts/Exo20/Exo20-Regular/Exo20-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Exo20/Exo20-Regular/Exo20-Regular.woff') format('woff'),
    url('../fonts/Exo20/Exo20-Regular/Exo20-Regular.ttf') format('truetype') {}
    font-weight: normal;
    font-style: normal; }
/*Exo20-Regular*/

/*Exo20-Medium*/
@font-face {
    font-family: 'Exo20';
    src: url('../fonts/Exo20/Exo20-Medium/Exo20-Medium.eot'),
    url('../fonts/Exo20/Exo20-Medium/Exo20-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Exo20/Exo20-Medium/Exo20-Medium.woff') format('woff'),
    url('../fonts/Exo20/Exo20-Medium/Exo20-Medium.ttf') format('truetype') {}
    font-weight: 500;
    font-style: normal; }
/*Exo20-Medium*/

/*Exo20-SemiBold*/
@font-face {
    font-family: 'Exo20';
    src: url('../fonts/Exo20/Exo20-SemiBold/Exo20-SemiBold.eot'),
    url('../fonts/Exo20/Exo20-SemiBold/Exo20-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Exo20/Exo20-SemiBold/Exo20-SemiBold.woff') format('woff'),
    url('../fonts/Exo20/Exo20-SemiBold/Exo20-SemiBold.ttf') format('truetype') {}
    font-weight: 600;
    font-style: normal; }
/*Exo20-SemiBold*/

/*Exo20-Bold*/
@font-face {
    font-family: 'Exo20';
    src: url('../fonts/Exo20/Exo20-Bold/Exo20-Bold.eot'),
    url('../fonts/Exo20/Exo20-Bold/Exo20-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Exo20/Exo20-Bold/Exo20-Bold.woff') format('woff'),
    url('../fonts/Exo20/Exo20-Bold/Exo20-Bold.ttf') format('truetype') {}
    font-weight: 700;
    font-style: normal; }
/*Exo20-Bold*/

/*Exo20-ExtraLight*/
@font-face {
    font-family: 'Exo20';
    src: url('../fonts/Exo20/Exo20-ExtraLight/Exo20-ExtraLight.eot'),
    url('../fonts/Exo20/Exo20-ExtraLight/Exo20-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Exo20/Exo20-ExtraLight/Exo20-ExtraLight.woff') format('woff'),
    url('../fonts/Exo20/Exo20-ExtraLight/Exo20-ExtraLightExo20-ExtraLight.ttf') format('truetype') {}
    font-weight: 200;
    font-style: normal; }
/*Exo20-ExtraLight*/

