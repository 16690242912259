html,
body {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: $c_Black;
    font-weight: 500;
    line-height: 1;
    font-family: $f_Exo; }

@media(min-width: 992px) and (max-width: 1199px) {
    html,
    body {
        font-size: 18px; } }

$placeholderSelectors: '::-webkit-input-placeholder' '::-moz-placeholder' ':-ms-input-placeholder' ':-moz-placeholder';
@each $selector in $placeholderSelectors {
    *#{$selector} {
        @include transition(0.3s); } }

* {
    outline: 0; }

main {
    flex-grow: 1; }

svg, svg path, a, button {
    outline: 0;
    box-shadow: none;
    @include transition(0.3s);
    &:hover {
        outline: 0;
        box-shadow: none; } }

a:hover {
    color: $c_AlizarinCrimson; }

h1 {
    font-weight: 400;
    font-size: 37px;
    letter-spacing: 1px;
    line-height: 1.17; }

h2 {
    font-size: 57px;
    font-weight: 200;
    letter-spacing: 2px; }

img {
    display: inline-block;
    width: auto;
    max-width: 100%;
    height: auto; }

.btn {
    display: inline-block;
    line-height: 1.2;
    font-weight: 500;
    font-size: 22px;
    padding: 7px 15px 10px;
    border-radius: 46px 0 46px 0;
    box-shadow: 4px 5px 7px 0px rgba(0,0,0,0.35); }
.btn-yellow {
    background: $c_Turbo;
    color: $c_Black;
    &:hover {
        background: $c_AlizarinCrimson;
        color: $c_White; } }
.btn-white {
    background: $c_White;
    color: $c_Black;
    &:hover {
        background: $c_AlizarinCrimson;
        color: $c_White; } }

.btn-round {
    border-radius: 26px;
    box-shadow: none; }

.btn-red {
    background: $c_AlizarinCrimson;
    color: $c_White;
    &:hover {
        background: $c_Turbo;
        color: $c_Black; } }

.btn-square {
    border-radius: 0;
    box-shadow: none; }


@media (min-width: 1460px) {
    .container {
        max-width: 1424px; } }

@media (max-width: 1199px) {
    h2 {
        font-size: 40px; } }

@media (max-width: 767px) {
    h2 {
        font-size: 28px; } }
