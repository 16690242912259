/*header*/
.header {
    background: $c_Masala;
    padding: 14px 0 21px;
    color: $c_White; }
.h {
    &-slog {
        color: $c_AlizarinCrimson;
        font-weight: 700;
        max-width: 183px;
        font-size: 21px;
        line-height: 1.1;
        padding-top: 36px;
        margin-bottom: 20px; }
    &-logo {
        margin: 0 auto 20px;
        max-width: 247px; }
    &-info {
        text-align: right;
        font-weight: 400;
        font-size: 21px;
        padding-top: 44px;
        letter-spacing: 0.02em;
        line-height: 1.2;
        margin-bottom: 20px;
        span {
            display: block;
            a {
                &:hover {
                    color: $c_Turbo; } } } }
    &-menu {
        margin-top: 23px;
        ul {
            display: flex;
            justify-content: center;
            li {
                margin: 0 23px 15px;
                a {
                    text-transform: uppercase;
                    font-size: 20px;
                    &:hover {
                        color: $c_Turbo; } } } } }
    &-menu_btn {
        width: 40px;
        border: 0;
        background: none;
        padding: 0;
        span {
            width: 100%;
            background: $c_White;
            height: 3px;
            display: block;
            border-radius: 20px;
            margin: 7px 0;
            &:first-child {
                margin-top: 0; }
            &:last-child {
                margin-bottom: 0; } } } }

/*header*/

/*first*/
.first {
    background: $c_AthensGray;
    background-repeat: no-repeat;
    background-position: 50% 0;
    &-ttl {
        font-size: 40px;
        padding-top: 106px;
        line-height: 1.05;
        margin-bottom: 46px;
        font-weight: 500;
        color: $c_Masala; }
    &-btn {
        max-width: 195px;
        width: 100%;
        &:hover {
            background: #c52b21;
            color: $c_White; } }

    &-pdf {
        padding-top: 40px;
        &-btn {
            display: flex;
            align-items: center;
            .img {
                width: 47px;
                margin-right: 36px; }
            .txt {
                text-decoration: underline;
                color: $c_AlizarinCrimson;
                margin-bottom: 4px; } } } }

/*first*/

/*first*/
.repr {
    margin-top: -93px;
    margin-bottom: -107px;
    position: relative;
    z-index: 2;
    &-wrap {
        background: $c_White;
        border-radius: 93px 0px 93px 0px;
        box-shadow: 0px 0px 21px 2px rgba(0,0,0,0.44);
        padding: 45px 68px 37px; }
    &-ttl {
        font-weight: 700;
        font-size: 23px;
        margin-bottom: 20px;
        letter-spacing: 0.5px;
        max-width: 583px; }
    &-txt-row {
        max-width: 625px; }
    &-row {
        align-items: center;
        justify-content: flex-end; }
    &-col {
        padding: 0 15px;
        width: 100%;
        max-width: 33%; }
    &-img {
        text-align: right; }
    &-txt {
        font-size: 19px;
        padding: 4px 0;
        line-height: 1.2;
        border-right: 2px solid $c_Turbo;
        &_col {
            &:nth-child(3) {
                .repr-txt {
                    border-right: 0; } } } } }
/*first*/

/*third*/
.third {
    background: $c_Masala;
    padding-top: 210px;
    padding-bottom: 118px;
    &-ttl {
        color: $c_Turbo;
        margin-bottom: 67px; }
    &-bl {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 51px;
        &-num {
            font-size: 57px;
            color: $c_Turbo;
            font-weight: 200;
            width: 40px;
            margin-right: 17px; }
        &-txt {
            color: $c_White;
            font-size: 25px;
            letter-spacing: 0.1px;
            flex: 1;
            font-weight: 400;
            padding-top: 6px; } }
    &-pdf {
        padding-top: 28px;
        &-btn {
            display: flex;
            align-items: center;
            .img {
                width: 47px;
                margin-right: 36px; }
            .txt {
                text-decoration: underline;
                color: $c_AlizarinCrimson;
                margin-bottom: 4px; } } }
    &-note {
        font-style: italic;
        color: $c_White;
        margin-top: 25px;
        font-size: 13px;
        letter-spacing: 1.2px;
        line-height: 1.3; } }
/*third*/

/*main_products*/
.main_products {
    padding: 90px 0;
    background: url('../img/main/form-back2.png') no-repeat 90% 60px, $c_AthensGray;
    &-ttl {
        letter-spacing: 1.1px;
        margin-bottom: 31px; }
    &-txt {
        font-size: 20px;
        line-height: 1.1;
        margin-bottom: 56px;
        p {
            margin-bottom: 25px; } }
    &-btn {
        display: inline-block;
        color: $c_Black;
        font-size: 18px;
        background-color: $c_Turbo;
        padding: 12px 24px;
        margin-bottom: 40px; }
    &-list {
        padding-top: 76px; }
    &-col {
        width: 100%;
        max-width: 20%; }
    &-bl {
        width: 100%;
        max-width: 230px;
        margin: 0 auto 26px;
        &-img {
            margin-bottom: 43px;
            display: block;
            border-radius: 40px 0px 40px 0;
            overflow: hidden;
            background: $c_White; }
        &-name {
            display: block;
            font-size: 20px;
            margin-bottom: 18px; }
        &-price {
            font-size: 20px; }
        &-btn {
            display: inline-block;
            color: $c_Black;
            padding: 5px 19px 7px;
            font-size: 16px; }
        &-pr {
            align-items: center;
            display: flex;
            justify-content: space-between; }
        &-art {
            color: #706F6F;
            font-size: 16px; } } }
/*main_products*/


/*feat*/
.feat {
    padding: 111px 0 35px;
    &-ttl {
        letter-spacing: 1.1px;
        margin-bottom: 47px;
        max-width: 767px; }
    &-subttl {
        display: flex;
        align-items: center; }
    &-img1 {
        width: 28px;
        height: auto;
        margin-right: 29px; }
    &-txt1 {
        font-weight: 700;
        font-size: 24px;
        color: $c_AlizarinCrimson; }
    &-detail {
        position: relative;
        margin-top: 53px;
        &-point {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 22px;
            .helpers {
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                .start {
                    border-radius: 50%;
                    background: $c_AlizarinCrimson;
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    display: block;
                    &:before {
                        content: '';
                        height: 1px;
                        position: absolute;
                        display: block;
                        background: $c_AlizarinCrimson; } }
                .end {
                    border-radius: 50%;
                    border: 1px solid $c_AlizarinCrimson;
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    display: block;
                    &:before {
                        content: '';
                        height: 1px;
                        position: absolute;
                        display: block;
                        background: $c_AlizarinCrimson; } } }

            &.point-1 {
                top: 26.6%;
                left: 7.2%;
                .helpers {
                    .start {
                        top: 8px;
                        left: -21px;
                        &:before {
                            top: 5px;
                            left: -36px;
                            width: 36px; } }
                    .end {
                        top: 264px;
                        left: 79px;
                        &:before {
                            top: -126px;
                            left: -210px;
                            width: 286px;
                            transform: rotate(61deg); } } } }
            &.point-2 {
                top: 13%;
                left: 40%;
                .helpers {
                    .start {
                        top: 8px;
                        left: -15px;
                        &:before {
                            top: 5px;
                            left: -35px;
                            width: 36px; } }
                    .end {
                        top: 262px;
                        left: -18px;
                        &:before {
                            top: -126px;
                            left: -140px;
                            width: 250px;
                            transform: rotate(82deg); } } } }
            &.point-3 {
                left: 66.2%;
                top: -4%;
                .helpers {
                    .start {
                        top: 8px;
                        left: -15px;
                        &:before {
                            top: 5px;
                            left: -38px;
                            width: 39px; } }
                    .end {
                        top: 282px;
                        left: -72px;
                        &:before {
                            top: -135px;
                            left: -123px;
                            width: 268px;
                            transform: rotate(93deg); } } } }
            &.point-4 {
                top: 55.7%;
                left: 76%;
                .helpers {
                    .start {
                        top: 8px;
                        left: -15px;
                        &:before {
                            top: 5px;
                            left: -48px;
                            width: 51px; } }
                    .end {
                        top: -165px;
                        left: 28px;
                        &:before {
                            top: 92px;
                            left: -142px;
                            width: 193px;
                            transform: rotate(119deg); } } } }
            &.point-5 {
                top: 84.2%;
                left: 44.7%;
                .helpers {
                    .start {
                        top: 8px;
                        left: -15px;
                        &:before {
                            top: 5px;
                            left: -58px;
                            width: 59px; } }
                    .end {
                        top: -45px;
                        left: -232px;
                        &:before {
                            top: 31px;
                            left: 4px;
                            width: 159px;
                            transform: rotate(19deg); } } } } } }

    &-side {
        background: $c_AthensGray;
        margin-top: -35px;
        padding: 52px 44px 30px;
        border-radius: 36px 36px 0px 0px;
        &-ttl {
            color: $c_AlizarinCrimson;
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 46px; }
        &-list {
            ul {
                li {
                    padding: 3px 0;
                    padding-left: 70px;
                    font-size: 20px;
                    margin-bottom: 35px;
                    min-height: 46px;
                    background: url('../img/pack3.svg')no-repeat 0% 0%;
                    -webkit-background-size: auto 46px;
                    background-size: auto 46px; } } } } }

/*feat*/

/*pack*/
.pack {
    background: url('../img/main/pack-back.png')no-repeat 50% 0%, $c_Masala;
    padding-top: 215px;
    padding-bottom: 144px;
    &-ttl {
        color: $c_White;
        letter-spacing: 1.1px;
        margin-bottom: 38px;
        max-width: 767px; }
    &-subttl {
        color: $c_Turbo;
        font-size: 18px;
        letter-spacing: 1.2px;
        max-width: 468px;
        line-height: 1.3;
        margin-bottom: 18px; }
    &-list {
        list-style: disc;
        color: #ffffff;
        margin-left: 22px;
        margin-bottom: 84px;
        li {
            margin-bottom: 8px;
            font-size: 19px;
            letter-spacing: 0.6px; } }
    &-img {
        max-width: 889px;
        overflow: hidden;
        border-radius: 100px 0px 100px 0px;
        &-wrap {
            position: relative;
            max-width: 889px; }
        &-liner {
            position: absolute;
            bottom: calc(100% + 84px);
            width: 465px;
            left: calc(100% + 32px);
            border: 2px solid $c_White;
            font-size: 0; }
        &:before {
            content: '';
            height: 2px;
            width: 104px;
            background: $c_White;
            position: absolute;
            right: -67px;
            transform: rotate(-69deg);
            top: -42px; } }
    &-sublist {
        &-point {
            display: flex;
            color: #ffffff;
            margin-bottom: 25px;
            font-size: 19px;
            letter-spacing: .6px;
            font-weight: 400; }
        &-img {
            height: 33px;
            width: 33px;
            margin-right: 34px; } } }


/*pack*/


/*brand*/
.brand {
    padding: 178px 0 106px;
    &-ttl {
        letter-spacing: 1.1px;
        margin-bottom: 40px;
        max-width: 420px; }
    &-txt {
        font-size: 19px;
        letter-spacing: 1px;
        line-height: 1.2;
        margin-bottom: 44px;
        strong {
            font-weight: 700; }
        p {
            margin-bottom: 25px; } }
    &-btn {
        margin-top: 5px;
        font-size: 16px;
        padding: 11px 15px 14px;
        width: 100%;
        max-width: 239px; }
    &-sert {
        margin: 12px auto 0;
        max-width: 379px;
        &-img {
            margin-bottom: 16px; }
        &-txt {
            display: flex;
            justify-content: center;
            font-size: 18px;
            img {
                max-width: 45px;
                margin-right: 24px; } } } }

/*brand*/

/*brand*/
.questions {
    padding: 124px 0 94px;
    background: url(../img/main/form-back2.png) no-repeat calc(((100vw - 668px) / 2) - 402px) 100%,url(../img/main/form-back.png) no-repeat calc((100vw + 668px) / 2) 60px, $c_AthensGray;
    &-ttl {
        letter-spacing: 1px;
        margin-bottom: 76px;
        text-align: center; }
    &-form {
        form {
            max-width: 668px;
            width: 100%;
            margin: 0 auto 80px;
            display: flex; }
        .wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output {
            display: none!important; }
        .wpcf7-not-valid-tip {
            display: none!important; }
        .wpcf7-not-valid {
            border-color: $c_AlizarinCrimson; } }

    &-input {
        flex: 1;
        input {
            width: 100%;
            font-size: 18px;
            padding: 25px 37px;
            letter-spacing: 1px;
            border: 1px solid $c_White; } }
    &-btn {
        width: 100%;
        max-width: 218px;
        button {
            width: 100%;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0.2px;
            padding: 22px 10px 26px; } }
    &-txt {
        text-align: center;
        font-size: 22px;
        letter-spacing: 0.3px; } }
/*brand*/

/*page_cat*/
.page_cat {
    &-first {
        padding-bottom: 167px;
        padding-top: 65px; }
    &-info {
        padding: 60px 0;
        &-ttl {
            letter-spacing: 0;
            margin-bottom: 63px;
            display: inline-block;
            font-size: 30px;
            position: relative;
            padding: 3px 30px 5px 10px;
            &:before {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background: #fee400;
                z-index: -1;
                top: 0;
                left: 0;
                border-radius: 0px 0px 56px 0px; } }
        &-txt {
            font-size: 20px;
            line-height: 1.15;
            font-weight: 400;
            letter-spacing: 0.1px;
            margin-bottom: 36px;
            color: #6d6d6d;
            overflow: hidden;
            p {
                margin-bottom: 21px; } }
        &-button {
            font-size: 12px;
            padding: 7px 15px;
            padding-right: 45px;
            background-image: url('../img/moretext.svg');
            background-position: 89% 50%;
            background-repeat: no-repeat;
            background-size: 20px auto;
            margin-bottom: 40px;
            &:hover {
                color: $c_Black!important;
                background-color: $c_Turbo!important;
                background-image: url('../img/moretext.svg') !important;
                background-position: 89% 50% !important;
                background-repeat: no-repeat !important;
                background-size: 20px auto !important; } } }

    &-full {
        padding: 60px 0; } }

/*page_cat*/

/*products*/
.products {
    &-list {
        padding-top: 76px; }
    &-col {
        width: 100%;
        max-width: 20%; }
    &-bl {
        width: 100%;
        max-width: 326px;
        margin: 0 auto 26px;
        &-img {
            margin-bottom: 50px;
            display: block;
            border-radius: 40px 0px 40px 0;
            overflow: hidden;
            background-color: #f1f1f3;
            text-align: center;
            font-size: 0;
            position: relative;
            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-image: url(../img/pattern.svg);
                background-repeat: no-repeat; } }
        &-name {
            display: block;
            font-size: 20px;
            margin-bottom: 15px; }
        &-art {
            font-size: 18px;
            margin-bottom: 32px; }
        &-price {
            font-size: 20px; }
        &-btn {
            display: inline-block;
            color: $c_Black;
            padding: 5px 19px 7px;
            font-size: 16px;
            background: #f1f1f3;
            &:hover {
                background-color: $c_AlizarinCrimson; } }
        &-pr {
            align-items: center;
            display: flex;
            justify-content: space-between;
            max-width: 230px; }
        &-art {
            color: #706F6F;
            font-size: 16px; } } }
/*products*/

/*prod*/
.prod {
    padding: 58px 0 0;
    &-main {
        &-img {
            font-size: 0;
            border-radius: 40px 0 40px 0;
            overflow: hidden;
            background-color: #f1f1f3;
            text-align: center;
            position: relative;
            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-image: url(../img/pattern.svg);
                background-repeat: no-repeat; } } }

    &-back {
        margin-bottom: 30px;
        a {
            background: url('../img/goback.svg')no-repeat 0% 50%;
            background-size: 20px auto;
            display: inline-block;
            padding: 2px 0px 2px 39px;
            font-size: 18px;
            color: #8f8d8c;
            text-decoration: underline; } }
    &-sliders {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px; }
    &-thumbs {
        max-width: 107px;
        width: 100%;
        margin-right: 63px; }
    &-slider {
        width: 100%;
        max-width: 496px; }
    &-thumb {
        &-img {
            overflow: hidden;
            border-radius: 10px 0px 10px 0px;
            cursor: pointer;
            margin-bottom: 22px;
            border: 2px solid transparent; } }
    &-slide {
        &-img {
            overflow: hidden;
            cursor: grab;
            border-radius: 64px 0px 64px 0px; } }

    .slick-current {
        .prod {
            &-thumb {
                &-img {
                    border-color: $c_Turbo; } } } }
    &-info {
        padding-left: 78px;
        margin-top: -7px;
        margin-bottom: 40px; }
    &-ttl {
        margin-bottom: 25px; }
    &-price {
        font-weight: 400;
        font-size: 32px;
        letter-spacing: 1px;
        margin-bottom: 45px; }
    &-order {
        a {
            font-size: 20px;
            padding: 10px 10px 12px;
            width: 100%;
            max-width: 205px; } }
    &-tabs {
        margin-top: 52px;
        border-bottom: 2px solid #6d6d6d;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 63px;
        a {
            font-size: 20px;
            padding: 15px 5px;
            margin-bottom: -2px;
            border-bottom: 2px solid transparent;
            margin-right: 46px;
            color: #6d6d6d;
            &.active {
                border-color: $c_Turbo;
                color: $c_Black; }
            &:last-child {
                margin-right: 0; } } }
    &-content {
        font-size: 19px;
        letter-spacing: 1px;
        line-height: 1.2;
        margin-bottom: 60px;
        h2 {
            margin-bottom: 40px; }
        .cont {
            display: none;
            &.active {
                display: block; } } }
    &-chars {
        max-width: 500px;
        &-row {
            border-bottom: 1px solid #8f8d8c;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            &:last-child {
                border-bottom: 0; }
            .char {
                color: #8f8d8c;
                width: 50%;
                padding: 11px 0px; }
            .value {
                width: 50%;
                padding: 11px 0px; } } }
    &-rel {
        padding: 124px 0 94px;
        background: url(../img/main/form-back2.png) no-repeat calc(((100vw - 668px)/2) - 402px) 100%,url(../img/main/form-back.png) no-repeat calc((100vw + 668px)/2) 60px, $c_AthensGray;
        &-ttl {
            letter-spacing: 1px;
            margin-bottom: 16px; } } }
/*prod*/

/*page_partn*/
.page_partn {
    &-first {
        .first-btn {
            max-width: none;
            width: auto;
            padding: 10px 58px 12px; } }
    &-advant {
        background: $c_Masala;
        padding-top: 106px;
        padding-bottom: 36px;
        &-ttl {
            color: $c_White;
            margin-bottom: 94px;
            max-width: 500px; }
        &-bl {
            margin: 0 auto;
            margin-bottom: 70px;
            max-width: 326px;
            &-logo {
                margin-bottom: 31px;
                height: 64px; }
            &-ttl {
                font-size: 18px;
                color: $c_White;
                font-weight: 700;
                margin-bottom: 4px;
                line-height: 1.1; }
            &-txt {
                font-size: 17px;
                color: $c_White;
                font-weight: 400;
                font-style: italic;
                line-height: 1.2; } }
        &-pdf {
            padding-top: 28px;
            &-btn {
                display: flex;
                align-items: center;
                .img {
                    width: 47px;
                    margin-right: 36px; }
                .txt {
                    text-decoration: underline;
                    color: $c_AlizarinCrimson;
                    margin-bottom: 4px; } } }
        &-note {
            font-style: italic;
            color: $c_White;
            margin-top: 25px;
            font-size: 13px;
            letter-spacing: 1.2px;
            line-height: 1.3; } }
    &-boxes {
        text-align: right;
        background: $c_AthensGray; }
    &-cont {
        padding: 0px 0 124px;
        background: $c_AthensGray;
        &-ttl {
            margin-bottom: 40px; }
        &-list {
            &-point {
                display: flex;
                color: $c_Black;
                margin-bottom: 25px;
                font-size: 19px;
                letter-spacing: .6px;
                font-weight: 400; }
            &-img {
                height: 33px;
                width: 33px;
                margin-right: 24px; } }
        &-mark {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 4px;
            max-width: 384px;
            .soc {
                width: 100%;
                max-width: 90px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: 15px;
                .ico {
                    max-width: 25px;
                    width: 100%;
                    &.wa {
                        max-width: 28px; } } }

            .txt {
                flex: 1;
                font-size: 18px;
                margin-bottom: 15px;
                max-width: 220px; } }
        &-phns {
            max-width: 406px;
            font-size: 20px;
            font-weight: 700;
            a {
                display: inline-block;
                margin-bottom: 10px; } }
        &-img {
            margin-bottom: 45px; } } }
/*page_partn*/

/*page_qual*/
.page_qual {
    &-info {
        padding: 96px 0;
        &-ttl {
            margin-bottom: 61px; }
        &-txt {
            font-size: 21px;
            margin-bottom: 35px;
            p {
                margin-bottom: 30px; } }
        &-doc {
            padding-top: 28px;
            &-btn {
                display: flex;
                align-items: center;
                margin-bottom: 75px;
                .img {
                    width: 33px;
                    margin-right: 28px; }
                .txt {
                    text-decoration: underline;
                    color: $c_AlizarinCrimson;
                    margin-bottom: 4px; } } }
        &-note {
            overflow: hidden;
            border-radius: 30px 0 30px 0;
            background: $c_AthensGray;
            padding: 30px 21px 42px;
            font-size: 19px;
            font-style: italic;
            max-width: 722px;
            img {
                display: block;
                width: 60px;
                margin-bottom: 17px; } }
        &-serts {
            &-bl {
                margin-bottom: 94px;
                &-imgs {
                    text-align: center;
                    img {
                        margin-bottom: 15px; } }
                &-txt {
                    text-align: center;
                    font-size: 19px;
                    font-weight: 500;
                    margin-top: 19px; } } } }

    &-pack {
        padding-top: 215px;
        padding-bottom: 144px;
        background: $c_AthensGray;
        &-ttl {
            color: $c_Black;
            letter-spacing: 1.1px;
            margin-bottom: 58px;
            max-width: 767px; }
        &-reg {
            max-width: 315px;
            .txt {
                font-size: 18px;
                letter-spacing: 1.2px;
                line-height: 1.3;
                margin-bottom: 14px;
                display: block; }
            .num {
                background: $c_White;
                padding: 14px;
                font-size: 18px; } }
        &-txt {
            color: $c_Black;
            font-size: 18px;
            letter-spacing: 1.2px;
            max-width: 468px;
            line-height: 1.3;
            margin-bottom: 18px;
            p {
                margin-bottom: 20px; }
            ul {
                margin-bottom: 20px;
                list-style: disc;
                margin-left: 22px;
                margin-bottom: 84px;
                li {
                    margin-bottom: 8px;
                    font-size: 19px;
                    letter-spacing: 0.6px; } } }
        &-img {
            max-width: 889px;
            overflow: hidden;
            border-radius: 100px 0px 100px 0px;
            &-wrap {
                position: relative;
                max-width: 889px; }
            &-liner {
                position: absolute;
                bottom: calc(100% + 84px);
                width: 465px;
                left: calc(100% + 32px);
                border: 2px solid $c_White;
                font-size: 0; }
            &:before {
                content: '';
                height: 2px;
                width: 104px;
                background: $c_White;
                position: absolute;
                right: -67px;
                transform: rotate(-69deg);
                top: -42px; } }
        &-sublist {
            margin-top: -11px;
            &-point {
                display: flex;
                margin-bottom: 25px;
                font-size: 19px;
                letter-spacing: .6px;
                font-weight: 400; }
            &-img {
                height: 33px;
                width: 33px;
                margin-right: 34px; } } } }
/*page_qual*/

/*page_about*/
.page_about {
    &-third {
        background: $c_Masala;
        padding-top: 237px;
        padding-bottom: 96px;
        &-ttl {
            color: $c_White;
            margin-bottom: 29px; }
        &-txt {
            padding-top: 11px;
            font-size: 18px;
            font-weight: 400;
            padding-right: 45px;
            color: $c_White;
            line-height: 1.1;
            p {
                margin-bottom: 27px; } } }
    &-spec {
        padding: 86px 0;
        &-txt {
            padding-top: 22px;
            font-size: 18px;
            font-weight: 400;
            max-width: 570px;
            line-height: 1.1;
            padding-bottom: 120px; }
        p {
            margin-bottom: 20px; }

        &-img {
            position: relative;
            z-index: 1;
            &_wrap {
                position: relative; } }
        &-point {
            position: absolute;
            z-index: 2;
            font-size: 19px;
            .helpers {
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                .start {
                    border-radius: 50%;
                    background: $c_AlizarinCrimson;
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    display: block;
                    &:before {
                        content: '';
                        height: 1px;
                        position: absolute;
                        display: block;
                        background: $c_AlizarinCrimson; } }
                .end {
                    border-radius: 50%;
                    border: 1px solid $c_AlizarinCrimson;
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    display: block;
                    &:before {
                        content: '';
                        height: 1px;
                        position: absolute;
                        display: block;
                        background: $c_AlizarinCrimson; } } }

            &.point1 {
                top: 237px;
                left: -278px;
                .helpers {
                    .start {
                        top: 11px;
                        left: 162px;
                        &:before {
                            width: 154px;
                            top: 4px;
                            left: 8px; } }
                    .end {
                        left: 435px;
                        top: -22px;
                        &:before {
                            width: 117px;
                            left: -115px;
                            top: 21px;
                            transform: rotate(-15deg); } } } }
            &.point2 {
                top: 301px;
                left: -201px;
                .helpers {
                    .start {
                        top: 11px;
                        left: 184px;
                        &:before {
                            width: 136px;
                            top: 4px;
                            left: 8px; } }
                    .end {
                        left: 456px;
                        top: -22px;
                        &:before {
                            width: 134px;
                            left: -134px;
                            top: 21px;
                            transform: rotate(-13deg); } } } } } }
    &-act {
        padding: 56px 0 0;
        margin-bottom: -170px;
        &-ttl {
            margin-bottom: 68px;
            max-width: 580px; }
        &-more {
            padding-top: 45px;
            margin-bottom: 30px;
            &-btn {
                display: flex;
                align-items: center;
                margin-bottom: 75px;
                font-size: 19px;
                .img {
                    width: 43px;
                    margin-right: 28px; }
                .txt {
                    text-decoration: underline;
                    color: $c_AlizarinCrimson;
                    margin-bottom: 4px; } } }
        &-list {
            &-bl {
                background: $c_Masala;
                border-radius: 26px 0 26px 0;
                padding: 50px 43px 36px;
                min-height: calc(100% - 30px);
                margin-bottom: 30px;
                &-top {
                    display: flex;
                    flex-wrap: wrap; }
                &-num {
                    font-size: 57px;
                    color: $c_Turbo;
                    font-weight: 200;
                    width: 40px;
                    margin-right: 6px;
                    margin-bottom: 19px; }
                &-ttl {
                    text-transform: uppercase;
                    font-weight: 300;
                    font-size: 20px;
                    color: $c_Turbo;
                    padding-top: 10px;
                    margin-bottom: 19px; }
                &-txt {
                    color: $c_White;
                    font-size: 20px; } } } }
    &-prod {
        background: $c_AthensGray;
        padding-top: 261px;
        padding-bottom: 85px;
        &-ttl {
            max-width: 500px;
            margin-bottom: 58px; }
        &-txt {
            padding-top: 3px;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.2;
            p {
                margin-bottom: 19px; } } }

    &-qual {
        background: $c_AthensGray;
        padding: 65px 0 92px;
        &-ttl {
            max-width: 450px;
            margin-bottom: 58px; }
        &-list {
            &-point {
                display: flex;
                margin-bottom: 42px;
                font-size: 18px;
                letter-spacing: .6px;
                font-weight: 400;
                line-height: 1.2;
                img {
                    height: 43px;
                    width: 43px;
                    margin-right: 34px; } } }
        &-serts {
            margin-top: 47px;
            &-bl {
                max-width: 299px;
                margin: 0 auto 30px;
                &-txt {
                    text-align: center;
                    font-size: 20px; }
                &-img {
                    margin-bottom: 43px; } } } }
    &-merits {
        padding: 76px 0;
        &-row {
            display: flex;
            flex-wrap: wrap; }
        &-col {
            width: 100%;
            max-width: 20%;
            border-right: 6px solid $c_AthensGray;
            padding: 14px 15px;
            &:last-child {
                border-right: 0; }
            &.per_year {
                max-width: 18%; }
            &.merits_third {
                max-width: 22%; } }
        &-bl {
            display: flex;
            width: 100%;
            align-items: center;
            flex-wrap: wrap;
            font-size: 18px;
            font-weight: 700;
            img {
                height: 67px;
                margin-right: 17px; }
            &.red {
                color: $c_AlizarinCrimson; }
            span {
                flex: 1; }
            .num {
                font-size: 38px;
                width: 100%;
                margin-bottom: 4px;
                flex: unset;
                padding: 0 10px;
                color: $c_AlizarinCrimson; }
            .num_txt {
                width: 100%;
                flex: unset;
                text-transform: uppercase;
                font-size: 23px;
                padding: 0 10px; } } } }

/*page_about*/

/*breadcrumbs*/
.breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 15px;
    margin-bottom: 42px;
    .crumb {
        margin-bottom: 10px;
        padding-right: 12px;
        margin-right: 5px;
        position: relative;
        &:before {
            content: '>';
            top: 1px;
            right: 0;
            position: absolute; } }
    .crumb-last {
        margin-bottom: 10px; } }
/*breadcrumbs*/

/*modl*/
.modl {
    &-wrap {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 60px 0; }
    &-overlay {
        z-index: 1;
        background: $c_Black;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.4;
        cursor: pointer; }
    &-cls {
        cursor: pointer;
        position: absolute;
        top: 37px;
        right: 64px;
        z-index: 4;
        width: 30px;
        height: 30px;
        font-size: 30px;
        &:before, &:after {
            content: '';
            position: absolute;
            background: $c_Turbo;
            height: 1px;
            width: 30px;
            top: 14px; }
        &:before {
            transform: rotate(45deg); }
        &:after {
            transform: rotate(-45deg); } }
    &-box {
        display: block;
        position: relative;
        z-index: 2;
        max-width: 700px;
        width: 100%;
        background: $c_White;
        margin: 0 auto;
        max-height: calc(100vh - 120px);
        border-radius: 40px 0 40px 0;
        overflow: hidden;
        &-in {
            overflow: auto;
            padding: 86px 101px;
            height: 100%;
            max-height: calc(100vh - 120px); }
        &-ttl {
            text-align: center;
            font-size: 40px;
            font-weight: 300;
            margin-bottom: 18px; }
        &-help {
            text-align: center;
            font-size: 14px;
            margin-bottom: 35px;
            color: $c_AlizarinCrimson; }
        &-input {
            margin-bottom: 24px;
            input {
                border: 2px solid $c_Turbo;
                padding: 12px 21px;
                font-size: 14px;
                width: 100%; }
            textarea {
                border: 2px solid $c_Turbo;
                padding: 12px 21px;
                font-size: 14px;
                width: 100%;
                height: 120px;
                resize: vertical; } }
        &-btn {
            button {
                font-size: 12px;
                padding: 10px 10px;
                width: 100%;
                max-width: 300px;
                margin: 0 auto; } }
        &-info {
            margin-top: 10px;
            &-logo {
                max-width: 39px;
                margin-bottom: 9px; }
            &-txt {
                font-size: 10px;
                margin-bottom: 7px;
                br+br {
                    display: none; } }
            &-txt2 {
                font-size: 10px;
                color: $c_AlizarinCrimson;
                br+br {
                    display: none; } } }
        &-form {
            width: 100%;
            .wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output {
                display: none!important; }
            .wpcf7-not-valid-tip {
                display: none!important; }
            .wpcf7-not-valid {
                border-color: $c_AlizarinCrimson; } } } }

/*modl*/

/*page_cont*/
.page_cont {
    &-info {
        padding: 90px 0 124px; }
    &-ttl {
        margin-bottom: 67px; }
    &-txt {
        font-size: 20px;
        line-height: 1.1;
        margin-bottom: 96px;
        strong {
            font-weight: 700; }
        p {
            margin-bottom: 22px; } }
    &-mark {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 4px;
        max-width: 384px;
        .soc {
            width: 100%;
            max-width: 90px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 15px;
            .ico {
                max-width: 25px;
                width: 100%;
                &.wa {
                    max-width: 28px; } } }

        .txt {
            flex: 1;
            font-size: 18px;
            margin-bottom: 15px;
            max-width: 220px; } }
    &-phns {
        max-width: 406px;
        font-size: 20px;
        font-weight: 700;
        a {
            display: inline-block;
            margin-bottom: 10px; } }
    &-img {
        margin-bottom: 45px; }

    &-work {
        background: #f1f1f3;
        overflow: hidden;
        border-radius: 40px 0 40px 0;
        padding: 45px 20px 30px;
        &-time {
            font-size: 18px;
            margin-bottom: 15px;
            padding-left: 50px;
            background: url('../img/contacts/time.svg')no-repeat 0% 0%;
            -webkit-background-size: 35px auto;
            background-size: 35px auto;
            .ttl {
                font-weight: 400; } }
        &-alert {
            font-style: italic;
            line-height: 1.2;
            margin-bottom: 15px;
            padding-left: 50px;
            background: url('../img/contacts/keycard.svg')no-repeat 0% 0%;
            -webkit-background-size: 35px auto;
            background-size: 36px auto; } } }

/*page_cont*/

/*footer*/
.footer {
    background: $c_Masala;
    padding: 50px 0 10px; }

.f {
    &-logo {
        margin-bottom: 25px; }
    &-menu {
        max-width: 611px;
        margin: 40px auto 0px;
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -15px;
            li {
                padding: 0 15px;
                width: 100%;
                max-width: 196px;
                margin-bottom: 25px;
                a {
                    text-transform: uppercase;
                    font-size: 16px;
                    text-decoration: underline;
                    color: $c_White;
                    font-weight: 400;
                    letter-spacing: 1px;
                    &:hover {
                        color: $c_Turbo; } } } } }
    &-info {
        text-align: right;
        font-weight: 400;
        font-size: 21px;
        padding-top: 38px;
        line-height: 1.2;
        margin-bottom: 14px;
        color: $c_White;
        span {
            display: block;
            margin-bottom: 11px;
            a {
                &:hover {
                    color: $c_Turbo; } } } }
    &-copy {
        font-size: 14px;
        color: #fff;
        line-height: 1.3;
        padding-bottom: 20px;
        opacity: 0.7; } }

/*footer*/

.main_page-first {
    padding-bottom: 146px;
    background-color: #ffe401; }

.page_about-first {
    padding-bottom: 146px; }

.page_partn-first {
    padding-bottom: 146px; }

.page_cat-first {
    padding-bottom: 146px;
    background-position: 177px 0; }

.page_qual-first {
    padding-bottom: 146px; }


/*style fixes*/
.first {
    &-pdf {
        display:none {} } }

.page_cat-info {
    padding: 100px 0; }

.page_partn-advant {
    padding-top: 136px; }

.page_qual-info {
    padding: 166px 0; }

.current_page_item a {
    color: #fee400; }

.btn:hover {
    box-shadow: 4px 5px 7px 0 rgba(0,0,0,.35) !important; }

/*style fixes*/
