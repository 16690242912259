/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain) */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-style: inherit;
  font-variant-ligatures: inherit;
  font-variant-caps: inherit;
  font-variant-numeric: inherit;
  font-variant-east-asian: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  vertical-align: baseline;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Custom */
*, *:after, *:before {
  box-sizing: border-box; }

input, textarea, select {
  border: none;
  appearance: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit; }

a, input, textarea, button, select,
a:focus, input:focus, textarea:focus, button:focus, select:focus,
a:active, input:active, textarea:active, button:active, select:active {
  outline: none; }

textarea {
  resize: none; }

img {
  display: block;
  max-width: 100%;
  border-style: none; }

a {
  color: inherit;
  text-decoration: none; }
  a:hover {
    color: inherit;
    text-decoration: none; }
  aactive {
    color: inherit; }

[hidden] {
  display: none; }

/*Exo20-Regular*/
@font-face {
  font-family: 'Exo20';
  src: url("../fonts/Exo20/Exo20-Regular/Exo20-Regular.eot"), url("../fonts/Exo20/Exo20-Regular/Exo20-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Exo20/Exo20-Regular/Exo20-Regular.woff") format("woff"), url("../fonts/Exo20/Exo20-Regular/Exo20-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

/*Exo20-Regular*/
/*Exo20-Medium*/
@font-face {
  font-family: 'Exo20';
  src: url("../fonts/Exo20/Exo20-Medium/Exo20-Medium.eot"), url("../fonts/Exo20/Exo20-Medium/Exo20-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Exo20/Exo20-Medium/Exo20-Medium.woff") format("woff"), url("../fonts/Exo20/Exo20-Medium/Exo20-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

/*Exo20-Medium*/
/*Exo20-SemiBold*/
@font-face {
  font-family: 'Exo20';
  src: url("../fonts/Exo20/Exo20-SemiBold/Exo20-SemiBold.eot"), url("../fonts/Exo20/Exo20-SemiBold/Exo20-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Exo20/Exo20-SemiBold/Exo20-SemiBold.woff") format("woff"), url("../fonts/Exo20/Exo20-SemiBold/Exo20-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

/*Exo20-SemiBold*/
/*Exo20-Bold*/
@font-face {
  font-family: 'Exo20';
  src: url("../fonts/Exo20/Exo20-Bold/Exo20-Bold.eot"), url("../fonts/Exo20/Exo20-Bold/Exo20-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Exo20/Exo20-Bold/Exo20-Bold.woff") format("woff"), url("../fonts/Exo20/Exo20-Bold/Exo20-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

/*Exo20-Bold*/
/*Exo20-ExtraLight*/
@font-face {
  font-family: 'Exo20';
  src: url("../fonts/Exo20/Exo20-ExtraLight/Exo20-ExtraLight.eot"), url("../fonts/Exo20/Exo20-ExtraLight/Exo20-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/Exo20/Exo20-ExtraLight/Exo20-ExtraLight.woff") format("woff"), url("../fonts/Exo20/Exo20-ExtraLight/Exo20-ExtraLightExo20-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal; }

/*Exo20-ExtraLight*/
html,
body {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  line-height: 1;
  font-family: "Exo20", sans-serif; }

@media (min-width: 992px) and (max-width: 1199px) {
  html,
  body {
    font-size: 18px; } }

*::-webkit-input-placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

*::-moz-placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

*:-ms-input-placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

*:-moz-placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

* {
  outline: 0; }

main {
  flex-grow: 1; }

svg, svg path, a, button {
  outline: 0;
  box-shadow: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  svg:hover, svg path:hover, a:hover, button:hover {
    outline: 0;
    box-shadow: none; }

a:hover {
  color: #E63327; }

h1 {
  font-weight: 400;
  font-size: 37px;
  letter-spacing: 1px;
  line-height: 1.17; }

h2 {
  font-size: 57px;
  font-weight: 200;
  letter-spacing: 2px; }

img {
  display: inline-block;
  width: auto;
  max-width: 100%;
  height: auto; }

.btn {
  display: inline-block;
  line-height: 1.2;
  font-weight: 500;
  font-size: 22px;
  padding: 7px 15px 10px;
  border-radius: 46px 0 46px 0;
  box-shadow: 4px 5px 7px 0px rgba(0, 0, 0, 0.35); }

.btn-yellow {
  background: #FEE400;
  color: #000000; }
  .btn-yellow:hover {
    background: #E63327;
    color: #FFFFFF; }

.btn-white {
  background: #FFFFFF;
  color: #000000; }
  .btn-white:hover {
    background: #E63327;
    color: #FFFFFF; }

.btn-round {
  border-radius: 26px;
  box-shadow: none; }

.btn-red {
  background: #E63327;
  color: #FFFFFF; }
  .btn-red:hover {
    background: #FEE400;
    color: #000000; }

.btn-square {
  border-radius: 0;
  box-shadow: none; }

@media (min-width: 1460px) {
  .container {
    max-width: 1424px; } }

@media (max-width: 1199px) {
  h2 {
    font-size: 40px; } }

@media (max-width: 767px) {
  h2 {
    font-size: 28px; } }

/*header*/
.header {
  background: #44413F;
  padding: 14px 0 21px;
  color: #FFFFFF; }

.h-slog {
  color: #E63327;
  font-weight: 700;
  max-width: 183px;
  font-size: 21px;
  line-height: 1.1;
  padding-top: 36px;
  margin-bottom: 20px; }

.h-logo {
  margin: 0 auto 20px;
  max-width: 247px; }

.h-info {
  text-align: right;
  font-weight: 400;
  font-size: 21px;
  padding-top: 44px;
  letter-spacing: 0.02em;
  line-height: 1.2;
  margin-bottom: 20px; }
  .h-info span {
    display: block; }
    .h-info span a:hover {
      color: #FEE400; }

.h-menu {
  margin-top: 23px; }
  .h-menu ul {
    display: flex;
    justify-content: center; }
    .h-menu ul li {
      margin: 0 23px 15px; }
      .h-menu ul li a {
        text-transform: uppercase;
        font-size: 20px; }
        .h-menu ul li a:hover {
          color: #FEE400; }

.h-menu_btn {
  width: 40px;
  border: 0;
  background: none;
  padding: 0; }
  .h-menu_btn span {
    width: 100%;
    background: #FFFFFF;
    height: 3px;
    display: block;
    border-radius: 20px;
    margin: 7px 0; }
    .h-menu_btn span:first-child {
      margin-top: 0; }
    .h-menu_btn span:last-child {
      margin-bottom: 0; }

/*header*/
/*first*/
.first {
  background: #f1f1f3;
  background-repeat: no-repeat;
  background-position: 50% 0; }
  .first-ttl {
    font-size: 40px;
    padding-top: 106px;
    line-height: 1.05;
    margin-bottom: 46px;
    font-weight: 500;
    color: #44413F; }
  .first-btn {
    max-width: 195px;
    width: 100%; }
    .first-btn:hover {
      background: #c52b21;
      color: #FFFFFF; }
  .first-pdf {
    padding-top: 40px; }
    .first-pdf-btn {
      display: flex;
      align-items: center; }
      .first-pdf-btn .img {
        width: 47px;
        margin-right: 36px; }
      .first-pdf-btn .txt {
        text-decoration: underline;
        color: #E63327;
        margin-bottom: 4px; }

/*first*/
/*first*/
.repr {
  margin-top: -93px;
  margin-bottom: -107px;
  position: relative;
  z-index: 2; }
  .repr-wrap {
    background: #FFFFFF;
    border-radius: 93px 0px 93px 0px;
    box-shadow: 0px 0px 21px 2px rgba(0, 0, 0, 0.44);
    padding: 45px 68px 37px; }
  .repr-ttl {
    font-weight: 700;
    font-size: 23px;
    margin-bottom: 20px;
    letter-spacing: 0.5px;
    max-width: 583px; }
  .repr-txt-row {
    max-width: 625px; }
  .repr-row {
    align-items: center;
    justify-content: flex-end; }
  .repr-col {
    padding: 0 15px;
    width: 100%;
    max-width: 33%; }
  .repr-img {
    text-align: right; }
  .repr-txt {
    font-size: 19px;
    padding: 4px 0;
    line-height: 1.2;
    border-right: 2px solid #FEE400; }
    .repr-txt_col:nth-child(3) .repr-txt {
      border-right: 0; }

/*first*/
/*third*/
.third {
  background: #44413F;
  padding-top: 210px;
  padding-bottom: 118px; }
  .third-ttl {
    color: #FEE400;
    margin-bottom: 67px; }
  .third-bl {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 51px; }
    .third-bl-num {
      font-size: 57px;
      color: #FEE400;
      font-weight: 200;
      width: 40px;
      margin-right: 17px; }
    .third-bl-txt {
      color: #FFFFFF;
      font-size: 25px;
      letter-spacing: 0.1px;
      flex: 1;
      font-weight: 400;
      padding-top: 6px; }
  .third-pdf {
    padding-top: 28px; }
    .third-pdf-btn {
      display: flex;
      align-items: center; }
      .third-pdf-btn .img {
        width: 47px;
        margin-right: 36px; }
      .third-pdf-btn .txt {
        text-decoration: underline;
        color: #E63327;
        margin-bottom: 4px; }
  .third-note {
    font-style: italic;
    color: #FFFFFF;
    margin-top: 25px;
    font-size: 13px;
    letter-spacing: 1.2px;
    line-height: 1.3; }

/*third*/
/*main_products*/
.main_products {
  padding: 90px 0;
  background: url("../img/main/form-back2.png") no-repeat 90% 60px, #f1f1f3; }
  .main_products-ttl {
    letter-spacing: 1.1px;
    margin-bottom: 31px; }
  .main_products-txt {
    font-size: 20px;
    line-height: 1.1;
    margin-bottom: 56px; }
    .main_products-txt p {
      margin-bottom: 25px; }
  .main_products-btn {
    display: inline-block;
    color: #000000;
    font-size: 18px;
    background-color: #FEE400;
    padding: 12px 24px;
    margin-bottom: 40px; }
  .main_products-list {
    padding-top: 76px; }
  .main_products-col {
    width: 100%;
    max-width: 20%; }
  .main_products-bl {
    width: 100%;
    max-width: 230px;
    margin: 0 auto 26px; }
    .main_products-bl-img {
      margin-bottom: 43px;
      display: block;
      border-radius: 40px 0px 40px 0;
      overflow: hidden;
      background: #FFFFFF; }
    .main_products-bl-name {
      display: block;
      font-size: 20px;
      margin-bottom: 18px; }
    .main_products-bl-price {
      font-size: 20px; }
    .main_products-bl-btn {
      display: inline-block;
      color: #000000;
      padding: 5px 19px 7px;
      font-size: 16px; }
    .main_products-bl-pr {
      align-items: center;
      display: flex;
      justify-content: space-between; }
    .main_products-bl-art {
      color: #706F6F;
      font-size: 16px; }

/*main_products*/
/*feat*/
.feat {
  padding: 111px 0 35px; }
  .feat-ttl {
    letter-spacing: 1.1px;
    margin-bottom: 47px;
    max-width: 767px; }
  .feat-subttl {
    display: flex;
    align-items: center; }
  .feat-img1 {
    width: 28px;
    height: auto;
    margin-right: 29px; }
  .feat-txt1 {
    font-weight: 700;
    font-size: 24px;
    color: #E63327; }
  .feat-detail {
    position: relative;
    margin-top: 53px; }
    .feat-detail-point {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 22px; }
      .feat-detail-point .helpers {
        position: absolute;
        display: block;
        top: 0;
        left: 0; }
        .feat-detail-point .helpers .start {
          border-radius: 50%;
          background: #E63327;
          width: 10px;
          height: 10px;
          position: absolute;
          display: block; }
          .feat-detail-point .helpers .start:before {
            content: '';
            height: 1px;
            position: absolute;
            display: block;
            background: #E63327; }
        .feat-detail-point .helpers .end {
          border-radius: 50%;
          border: 1px solid #E63327;
          width: 10px;
          height: 10px;
          position: absolute;
          display: block; }
          .feat-detail-point .helpers .end:before {
            content: '';
            height: 1px;
            position: absolute;
            display: block;
            background: #E63327; }
      .feat-detail-point.point-1 {
        top: 26.6%;
        left: 7.2%; }
        .feat-detail-point.point-1 .helpers .start {
          top: 8px;
          left: -21px; }
          .feat-detail-point.point-1 .helpers .start:before {
            top: 5px;
            left: -36px;
            width: 36px; }
        .feat-detail-point.point-1 .helpers .end {
          top: 264px;
          left: 79px; }
          .feat-detail-point.point-1 .helpers .end:before {
            top: -126px;
            left: -210px;
            width: 286px;
            transform: rotate(61deg); }
      .feat-detail-point.point-2 {
        top: 13%;
        left: 40%; }
        .feat-detail-point.point-2 .helpers .start {
          top: 8px;
          left: -15px; }
          .feat-detail-point.point-2 .helpers .start:before {
            top: 5px;
            left: -35px;
            width: 36px; }
        .feat-detail-point.point-2 .helpers .end {
          top: 262px;
          left: -18px; }
          .feat-detail-point.point-2 .helpers .end:before {
            top: -126px;
            left: -140px;
            width: 250px;
            transform: rotate(82deg); }
      .feat-detail-point.point-3 {
        left: 66.2%;
        top: -4%; }
        .feat-detail-point.point-3 .helpers .start {
          top: 8px;
          left: -15px; }
          .feat-detail-point.point-3 .helpers .start:before {
            top: 5px;
            left: -38px;
            width: 39px; }
        .feat-detail-point.point-3 .helpers .end {
          top: 282px;
          left: -72px; }
          .feat-detail-point.point-3 .helpers .end:before {
            top: -135px;
            left: -123px;
            width: 268px;
            transform: rotate(93deg); }
      .feat-detail-point.point-4 {
        top: 55.7%;
        left: 76%; }
        .feat-detail-point.point-4 .helpers .start {
          top: 8px;
          left: -15px; }
          .feat-detail-point.point-4 .helpers .start:before {
            top: 5px;
            left: -48px;
            width: 51px; }
        .feat-detail-point.point-4 .helpers .end {
          top: -165px;
          left: 28px; }
          .feat-detail-point.point-4 .helpers .end:before {
            top: 92px;
            left: -142px;
            width: 193px;
            transform: rotate(119deg); }
      .feat-detail-point.point-5 {
        top: 84.2%;
        left: 44.7%; }
        .feat-detail-point.point-5 .helpers .start {
          top: 8px;
          left: -15px; }
          .feat-detail-point.point-5 .helpers .start:before {
            top: 5px;
            left: -58px;
            width: 59px; }
        .feat-detail-point.point-5 .helpers .end {
          top: -45px;
          left: -232px; }
          .feat-detail-point.point-5 .helpers .end:before {
            top: 31px;
            left: 4px;
            width: 159px;
            transform: rotate(19deg); }
  .feat-side {
    background: #f1f1f3;
    margin-top: -35px;
    padding: 52px 44px 30px;
    border-radius: 36px 36px 0px 0px; }
    .feat-side-ttl {
      color: #E63327;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 46px; }
    .feat-side-list ul li {
      padding: 3px 0;
      padding-left: 70px;
      font-size: 20px;
      margin-bottom: 35px;
      min-height: 46px;
      background: url("../img/pack3.svg") no-repeat 0% 0%;
      -webkit-background-size: auto 46px;
      background-size: auto 46px; }

/*feat*/
/*pack*/
.pack {
  background: url("../img/main/pack-back.png") no-repeat 50% 0%, #44413F;
  padding-top: 215px;
  padding-bottom: 144px; }
  .pack-ttl {
    color: #FFFFFF;
    letter-spacing: 1.1px;
    margin-bottom: 38px;
    max-width: 767px; }
  .pack-subttl {
    color: #FEE400;
    font-size: 18px;
    letter-spacing: 1.2px;
    max-width: 468px;
    line-height: 1.3;
    margin-bottom: 18px; }
  .pack-list {
    list-style: disc;
    color: #ffffff;
    margin-left: 22px;
    margin-bottom: 84px; }
    .pack-list li {
      margin-bottom: 8px;
      font-size: 19px;
      letter-spacing: 0.6px; }
  .pack-img {
    max-width: 889px;
    overflow: hidden;
    border-radius: 100px 0px 100px 0px; }
    .pack-img-wrap {
      position: relative;
      max-width: 889px; }
    .pack-img-liner {
      position: absolute;
      bottom: calc(100% + 84px);
      width: 465px;
      left: calc(100% + 32px);
      border: 2px solid #FFFFFF;
      font-size: 0; }
    .pack-img:before {
      content: '';
      height: 2px;
      width: 104px;
      background: #FFFFFF;
      position: absolute;
      right: -67px;
      transform: rotate(-69deg);
      top: -42px; }
  .pack-sublist-point {
    display: flex;
    color: #ffffff;
    margin-bottom: 25px;
    font-size: 19px;
    letter-spacing: .6px;
    font-weight: 400; }
  .pack-sublist-img {
    height: 33px;
    width: 33px;
    margin-right: 34px; }

/*pack*/
/*brand*/
.brand {
  padding: 178px 0 106px; }
  .brand-ttl {
    letter-spacing: 1.1px;
    margin-bottom: 40px;
    max-width: 420px; }
  .brand-txt {
    font-size: 19px;
    letter-spacing: 1px;
    line-height: 1.2;
    margin-bottom: 44px; }
    .brand-txt strong {
      font-weight: 700; }
    .brand-txt p {
      margin-bottom: 25px; }
  .brand-btn {
    margin-top: 5px;
    font-size: 16px;
    padding: 11px 15px 14px;
    width: 100%;
    max-width: 239px; }
  .brand-sert {
    margin: 12px auto 0;
    max-width: 379px; }
    .brand-sert-img {
      margin-bottom: 16px; }
    .brand-sert-txt {
      display: flex;
      justify-content: center;
      font-size: 18px; }
      .brand-sert-txt img {
        max-width: 45px;
        margin-right: 24px; }

/*brand*/
/*brand*/
.questions {
  padding: 124px 0 94px;
  background: url(../img/main/form-back2.png) no-repeat calc(((100vw - 668px) / 2) - 402px) 100%, url(../img/main/form-back.png) no-repeat calc((100vw + 668px) / 2) 60px, #f1f1f3; }
  .questions-ttl {
    letter-spacing: 1px;
    margin-bottom: 76px;
    text-align: center; }
  .questions-form form {
    max-width: 668px;
    width: 100%;
    margin: 0 auto 80px;
    display: flex; }
  .questions-form .wpcf7 form.invalid .wpcf7-response-output, .questions-form .wpcf7 form.unaccepted .wpcf7-response-output {
    display: none !important; }
  .questions-form .wpcf7-not-valid-tip {
    display: none !important; }
  .questions-form .wpcf7-not-valid {
    border-color: #E63327; }
  .questions-input {
    flex: 1; }
    .questions-input input {
      width: 100%;
      font-size: 18px;
      padding: 25px 37px;
      letter-spacing: 1px;
      border: 1px solid #FFFFFF; }
  .questions-btn {
    width: 100%;
    max-width: 218px; }
    .questions-btn button {
      width: 100%;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.2px;
      padding: 22px 10px 26px; }
  .questions-txt {
    text-align: center;
    font-size: 22px;
    letter-spacing: 0.3px; }

/*brand*/
/*page_cat*/
.page_cat-first {
  padding-bottom: 167px;
  padding-top: 65px; }

.page_cat-info {
  padding: 60px 0; }
  .page_cat-info-ttl {
    letter-spacing: 0;
    margin-bottom: 63px;
    display: inline-block;
    font-size: 30px;
    position: relative;
    padding: 3px 30px 5px 10px; }
    .page_cat-info-ttl:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: #fee400;
      z-index: -1;
      top: 0;
      left: 0;
      border-radius: 0px 0px 56px 0px; }
  .page_cat-info-txt {
    font-size: 20px;
    line-height: 1.15;
    font-weight: 400;
    letter-spacing: 0.1px;
    margin-bottom: 36px;
    color: #6d6d6d;
    overflow: hidden; }
    .page_cat-info-txt p {
      margin-bottom: 21px; }
  .page_cat-info-button {
    font-size: 12px;
    padding: 7px 15px;
    padding-right: 45px;
    background-image: url("../img/moretext.svg");
    background-position: 89% 50%;
    background-repeat: no-repeat;
    background-size: 20px auto;
    margin-bottom: 40px; }
    .page_cat-info-button:hover {
      color: #000000 !important;
      background-color: #FEE400 !important;
      background-image: url("../img/moretext.svg") !important;
      background-position: 89% 50% !important;
      background-repeat: no-repeat !important;
      background-size: 20px auto !important; }

.page_cat-full {
  padding: 60px 0; }

/*page_cat*/
/*products*/
.products-list {
  padding-top: 76px; }

.products-col {
  width: 100%;
  max-width: 20%; }

.products-bl {
  width: 100%;
  max-width: 326px;
  margin: 0 auto 26px; }
  .products-bl-img {
    margin-bottom: 50px;
    display: block;
    border-radius: 40px 0px 40px 0;
    overflow: hidden;
    background-color: #f1f1f3;
    text-align: center;
    font-size: 0;
    position: relative; }
    .products-bl-img:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../img/pattern.svg);
      background-repeat: no-repeat; }
  .products-bl-name {
    display: block;
    font-size: 20px;
    margin-bottom: 15px; }
  .products-bl-art {
    font-size: 18px;
    margin-bottom: 32px; }
  .products-bl-price {
    font-size: 20px; }
  .products-bl-btn {
    display: inline-block;
    color: #000000;
    padding: 5px 19px 7px;
    font-size: 16px;
    background: #f1f1f3; }
    .products-bl-btn:hover {
      background-color: #E63327; }
  .products-bl-pr {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 230px; }
  .products-bl-art {
    color: #706F6F;
    font-size: 16px; }

/*products*/
/*prod*/
.prod {
  padding: 58px 0 0; }
  .prod-main-img {
    font-size: 0;
    border-radius: 40px 0 40px 0;
    overflow: hidden;
    background-color: #f1f1f3;
    text-align: center;
    position: relative; }
    .prod-main-img:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../img/pattern.svg);
      background-repeat: no-repeat; }
  .prod-back {
    margin-bottom: 30px; }
    .prod-back a {
      background: url("../img/goback.svg") no-repeat 0% 50%;
      background-size: 20px auto;
      display: inline-block;
      padding: 2px 0px 2px 39px;
      font-size: 18px;
      color: #8f8d8c;
      text-decoration: underline; }
  .prod-sliders {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px; }
  .prod-thumbs {
    max-width: 107px;
    width: 100%;
    margin-right: 63px; }
  .prod-slider {
    width: 100%;
    max-width: 496px; }
  .prod-thumb-img {
    overflow: hidden;
    border-radius: 10px 0px 10px 0px;
    cursor: pointer;
    margin-bottom: 22px;
    border: 2px solid transparent; }
  .prod-slide-img {
    overflow: hidden;
    cursor: grab;
    border-radius: 64px 0px 64px 0px; }
  .prod .slick-current .prod-thumb-img {
    border-color: #FEE400; }
  .prod-info {
    padding-left: 78px;
    margin-top: -7px;
    margin-bottom: 40px; }
  .prod-ttl {
    margin-bottom: 25px; }
  .prod-price {
    font-weight: 400;
    font-size: 32px;
    letter-spacing: 1px;
    margin-bottom: 45px; }
  .prod-order a {
    font-size: 20px;
    padding: 10px 10px 12px;
    width: 100%;
    max-width: 205px; }
  .prod-tabs {
    margin-top: 52px;
    border-bottom: 2px solid #6d6d6d;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 63px; }
    .prod-tabs a {
      font-size: 20px;
      padding: 15px 5px;
      margin-bottom: -2px;
      border-bottom: 2px solid transparent;
      margin-right: 46px;
      color: #6d6d6d; }
      .prod-tabs a.active {
        border-color: #FEE400;
        color: #000000; }
      .prod-tabs a:last-child {
        margin-right: 0; }
  .prod-content {
    font-size: 19px;
    letter-spacing: 1px;
    line-height: 1.2;
    margin-bottom: 60px; }
    .prod-content h2 {
      margin-bottom: 40px; }
    .prod-content .cont {
      display: none; }
      .prod-content .cont.active {
        display: block; }
  .prod-chars {
    max-width: 500px; }
    .prod-chars-row {
      border-bottom: 1px solid #8f8d8c;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start; }
      .prod-chars-row:last-child {
        border-bottom: 0; }
      .prod-chars-row .char {
        color: #8f8d8c;
        width: 50%;
        padding: 11px 0px; }
      .prod-chars-row .value {
        width: 50%;
        padding: 11px 0px; }
  .prod-rel {
    padding: 124px 0 94px;
    background: url(../img/main/form-back2.png) no-repeat calc(((100vw - 668px)/2) - 402px) 100%, url(../img/main/form-back.png) no-repeat calc((100vw + 668px)/2) 60px, #f1f1f3; }
    .prod-rel-ttl {
      letter-spacing: 1px;
      margin-bottom: 16px; }

/*prod*/
/*page_partn*/
.page_partn-first .first-btn {
  max-width: none;
  width: auto;
  padding: 10px 58px 12px; }

.page_partn-advant {
  background: #44413F;
  padding-top: 106px;
  padding-bottom: 36px; }
  .page_partn-advant-ttl {
    color: #FFFFFF;
    margin-bottom: 94px;
    max-width: 500px; }
  .page_partn-advant-bl {
    margin: 0 auto;
    margin-bottom: 70px;
    max-width: 326px; }
    .page_partn-advant-bl-logo {
      margin-bottom: 31px;
      height: 64px; }
    .page_partn-advant-bl-ttl {
      font-size: 18px;
      color: #FFFFFF;
      font-weight: 700;
      margin-bottom: 4px;
      line-height: 1.1; }
    .page_partn-advant-bl-txt {
      font-size: 17px;
      color: #FFFFFF;
      font-weight: 400;
      font-style: italic;
      line-height: 1.2; }
  .page_partn-advant-pdf {
    padding-top: 28px; }
    .page_partn-advant-pdf-btn {
      display: flex;
      align-items: center; }
      .page_partn-advant-pdf-btn .img {
        width: 47px;
        margin-right: 36px; }
      .page_partn-advant-pdf-btn .txt {
        text-decoration: underline;
        color: #E63327;
        margin-bottom: 4px; }
  .page_partn-advant-note {
    font-style: italic;
    color: #FFFFFF;
    margin-top: 25px;
    font-size: 13px;
    letter-spacing: 1.2px;
    line-height: 1.3; }

.page_partn-boxes {
  text-align: right;
  background: #f1f1f3; }

.page_partn-cont {
  padding: 0px 0 124px;
  background: #f1f1f3; }
  .page_partn-cont-ttl {
    margin-bottom: 40px; }
  .page_partn-cont-list-point {
    display: flex;
    color: #000000;
    margin-bottom: 25px;
    font-size: 19px;
    letter-spacing: .6px;
    font-weight: 400; }
  .page_partn-cont-list-img {
    height: 33px;
    width: 33px;
    margin-right: 24px; }
  .page_partn-cont-mark {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 4px;
    max-width: 384px; }
    .page_partn-cont-mark .soc {
      width: 100%;
      max-width: 90px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 15px; }
      .page_partn-cont-mark .soc .ico {
        max-width: 25px;
        width: 100%; }
        .page_partn-cont-mark .soc .ico.wa {
          max-width: 28px; }
    .page_partn-cont-mark .txt {
      flex: 1;
      font-size: 18px;
      margin-bottom: 15px;
      max-width: 220px; }
  .page_partn-cont-phns {
    max-width: 406px;
    font-size: 20px;
    font-weight: 700; }
    .page_partn-cont-phns a {
      display: inline-block;
      margin-bottom: 10px; }
  .page_partn-cont-img {
    margin-bottom: 45px; }

/*page_partn*/
/*page_qual*/
.page_qual-info {
  padding: 96px 0; }
  .page_qual-info-ttl {
    margin-bottom: 61px; }
  .page_qual-info-txt {
    font-size: 21px;
    margin-bottom: 35px; }
    .page_qual-info-txt p {
      margin-bottom: 30px; }
  .page_qual-info-doc {
    padding-top: 28px; }
    .page_qual-info-doc-btn {
      display: flex;
      align-items: center;
      margin-bottom: 75px; }
      .page_qual-info-doc-btn .img {
        width: 33px;
        margin-right: 28px; }
      .page_qual-info-doc-btn .txt {
        text-decoration: underline;
        color: #E63327;
        margin-bottom: 4px; }
  .page_qual-info-note {
    overflow: hidden;
    border-radius: 30px 0 30px 0;
    background: #f1f1f3;
    padding: 30px 21px 42px;
    font-size: 19px;
    font-style: italic;
    max-width: 722px; }
    .page_qual-info-note img {
      display: block;
      width: 60px;
      margin-bottom: 17px; }
  .page_qual-info-serts-bl {
    margin-bottom: 94px; }
    .page_qual-info-serts-bl-imgs {
      text-align: center; }
      .page_qual-info-serts-bl-imgs img {
        margin-bottom: 15px; }
    .page_qual-info-serts-bl-txt {
      text-align: center;
      font-size: 19px;
      font-weight: 500;
      margin-top: 19px; }

.page_qual-pack {
  padding-top: 215px;
  padding-bottom: 144px;
  background: #f1f1f3; }
  .page_qual-pack-ttl {
    color: #000000;
    letter-spacing: 1.1px;
    margin-bottom: 58px;
    max-width: 767px; }
  .page_qual-pack-reg {
    max-width: 315px; }
    .page_qual-pack-reg .txt {
      font-size: 18px;
      letter-spacing: 1.2px;
      line-height: 1.3;
      margin-bottom: 14px;
      display: block; }
    .page_qual-pack-reg .num {
      background: #FFFFFF;
      padding: 14px;
      font-size: 18px; }
  .page_qual-pack-txt {
    color: #000000;
    font-size: 18px;
    letter-spacing: 1.2px;
    max-width: 468px;
    line-height: 1.3;
    margin-bottom: 18px; }
    .page_qual-pack-txt p {
      margin-bottom: 20px; }
    .page_qual-pack-txt ul {
      margin-bottom: 20px;
      list-style: disc;
      margin-left: 22px;
      margin-bottom: 84px; }
      .page_qual-pack-txt ul li {
        margin-bottom: 8px;
        font-size: 19px;
        letter-spacing: 0.6px; }
  .page_qual-pack-img {
    max-width: 889px;
    overflow: hidden;
    border-radius: 100px 0px 100px 0px; }
    .page_qual-pack-img-wrap {
      position: relative;
      max-width: 889px; }
    .page_qual-pack-img-liner {
      position: absolute;
      bottom: calc(100% + 84px);
      width: 465px;
      left: calc(100% + 32px);
      border: 2px solid #FFFFFF;
      font-size: 0; }
    .page_qual-pack-img:before {
      content: '';
      height: 2px;
      width: 104px;
      background: #FFFFFF;
      position: absolute;
      right: -67px;
      transform: rotate(-69deg);
      top: -42px; }
  .page_qual-pack-sublist {
    margin-top: -11px; }
    .page_qual-pack-sublist-point {
      display: flex;
      margin-bottom: 25px;
      font-size: 19px;
      letter-spacing: .6px;
      font-weight: 400; }
    .page_qual-pack-sublist-img {
      height: 33px;
      width: 33px;
      margin-right: 34px; }

/*page_qual*/
/*page_about*/
.page_about-third {
  background: #44413F;
  padding-top: 237px;
  padding-bottom: 96px; }
  .page_about-third-ttl {
    color: #FFFFFF;
    margin-bottom: 29px; }
  .page_about-third-txt {
    padding-top: 11px;
    font-size: 18px;
    font-weight: 400;
    padding-right: 45px;
    color: #FFFFFF;
    line-height: 1.1; }
    .page_about-third-txt p {
      margin-bottom: 27px; }

.page_about-spec {
  padding: 86px 0; }
  .page_about-spec-txt {
    padding-top: 22px;
    font-size: 18px;
    font-weight: 400;
    max-width: 570px;
    line-height: 1.1;
    padding-bottom: 120px; }
  .page_about-spec p {
    margin-bottom: 20px; }
  .page_about-spec-img {
    position: relative;
    z-index: 1; }
    .page_about-spec-img_wrap {
      position: relative; }
  .page_about-spec-point {
    position: absolute;
    z-index: 2;
    font-size: 19px; }
    .page_about-spec-point .helpers {
      position: absolute;
      display: block;
      top: 0;
      left: 0; }
      .page_about-spec-point .helpers .start {
        border-radius: 50%;
        background: #E63327;
        width: 10px;
        height: 10px;
        position: absolute;
        display: block; }
        .page_about-spec-point .helpers .start:before {
          content: '';
          height: 1px;
          position: absolute;
          display: block;
          background: #E63327; }
      .page_about-spec-point .helpers .end {
        border-radius: 50%;
        border: 1px solid #E63327;
        width: 10px;
        height: 10px;
        position: absolute;
        display: block; }
        .page_about-spec-point .helpers .end:before {
          content: '';
          height: 1px;
          position: absolute;
          display: block;
          background: #E63327; }
    .page_about-spec-point.point1 {
      top: 237px;
      left: -278px; }
      .page_about-spec-point.point1 .helpers .start {
        top: 11px;
        left: 162px; }
        .page_about-spec-point.point1 .helpers .start:before {
          width: 154px;
          top: 4px;
          left: 8px; }
      .page_about-spec-point.point1 .helpers .end {
        left: 435px;
        top: -22px; }
        .page_about-spec-point.point1 .helpers .end:before {
          width: 117px;
          left: -115px;
          top: 21px;
          transform: rotate(-15deg); }
    .page_about-spec-point.point2 {
      top: 301px;
      left: -201px; }
      .page_about-spec-point.point2 .helpers .start {
        top: 11px;
        left: 184px; }
        .page_about-spec-point.point2 .helpers .start:before {
          width: 136px;
          top: 4px;
          left: 8px; }
      .page_about-spec-point.point2 .helpers .end {
        left: 456px;
        top: -22px; }
        .page_about-spec-point.point2 .helpers .end:before {
          width: 134px;
          left: -134px;
          top: 21px;
          transform: rotate(-13deg); }

.page_about-act {
  padding: 56px 0 0;
  margin-bottom: -170px; }
  .page_about-act-ttl {
    margin-bottom: 68px;
    max-width: 580px; }
  .page_about-act-more {
    padding-top: 45px;
    margin-bottom: 30px; }
    .page_about-act-more-btn {
      display: flex;
      align-items: center;
      margin-bottom: 75px;
      font-size: 19px; }
      .page_about-act-more-btn .img {
        width: 43px;
        margin-right: 28px; }
      .page_about-act-more-btn .txt {
        text-decoration: underline;
        color: #E63327;
        margin-bottom: 4px; }
  .page_about-act-list-bl {
    background: #44413F;
    border-radius: 26px 0 26px 0;
    padding: 50px 43px 36px;
    min-height: calc(100% - 30px);
    margin-bottom: 30px; }
    .page_about-act-list-bl-top {
      display: flex;
      flex-wrap: wrap; }
    .page_about-act-list-bl-num {
      font-size: 57px;
      color: #FEE400;
      font-weight: 200;
      width: 40px;
      margin-right: 6px;
      margin-bottom: 19px; }
    .page_about-act-list-bl-ttl {
      text-transform: uppercase;
      font-weight: 300;
      font-size: 20px;
      color: #FEE400;
      padding-top: 10px;
      margin-bottom: 19px; }
    .page_about-act-list-bl-txt {
      color: #FFFFFF;
      font-size: 20px; }

.page_about-prod {
  background: #f1f1f3;
  padding-top: 261px;
  padding-bottom: 85px; }
  .page_about-prod-ttl {
    max-width: 500px;
    margin-bottom: 58px; }
  .page_about-prod-txt {
    padding-top: 3px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2; }
    .page_about-prod-txt p {
      margin-bottom: 19px; }

.page_about-qual {
  background: #f1f1f3;
  padding: 65px 0 92px; }
  .page_about-qual-ttl {
    max-width: 450px;
    margin-bottom: 58px; }
  .page_about-qual-list-point {
    display: flex;
    margin-bottom: 42px;
    font-size: 18px;
    letter-spacing: .6px;
    font-weight: 400;
    line-height: 1.2; }
    .page_about-qual-list-point img {
      height: 43px;
      width: 43px;
      margin-right: 34px; }
  .page_about-qual-serts {
    margin-top: 47px; }
    .page_about-qual-serts-bl {
      max-width: 299px;
      margin: 0 auto 30px; }
      .page_about-qual-serts-bl-txt {
        text-align: center;
        font-size: 20px; }
      .page_about-qual-serts-bl-img {
        margin-bottom: 43px; }

.page_about-merits {
  padding: 76px 0; }
  .page_about-merits-row {
    display: flex;
    flex-wrap: wrap; }
  .page_about-merits-col {
    width: 100%;
    max-width: 20%;
    border-right: 6px solid #f1f1f3;
    padding: 14px 15px; }
    .page_about-merits-col:last-child {
      border-right: 0; }
    .page_about-merits-col.per_year {
      max-width: 18%; }
    .page_about-merits-col.merits_third {
      max-width: 22%; }
  .page_about-merits-bl {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: 700; }
    .page_about-merits-bl img {
      height: 67px;
      margin-right: 17px; }
    .page_about-merits-bl.red {
      color: #E63327; }
    .page_about-merits-bl span {
      flex: 1; }
    .page_about-merits-bl .num {
      font-size: 38px;
      width: 100%;
      margin-bottom: 4px;
      flex: unset;
      padding: 0 10px;
      color: #E63327; }
    .page_about-merits-bl .num_txt {
      width: 100%;
      flex: unset;
      text-transform: uppercase;
      font-size: 23px;
      padding: 0 10px; }

/*page_about*/
/*breadcrumbs*/
.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 15px;
  margin-bottom: 42px; }
  .breadcrumbs .crumb {
    margin-bottom: 10px;
    padding-right: 12px;
    margin-right: 5px;
    position: relative; }
    .breadcrumbs .crumb:before {
      content: '>';
      top: 1px;
      right: 0;
      position: absolute; }
  .breadcrumbs .crumb-last {
    margin-bottom: 10px; }

/*breadcrumbs*/
/*modl*/
.modl-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0; }

.modl-overlay {
  z-index: 1;
  background: #000000;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.4;
  cursor: pointer; }

.modl-cls {
  cursor: pointer;
  position: absolute;
  top: 37px;
  right: 64px;
  z-index: 4;
  width: 30px;
  height: 30px;
  font-size: 30px; }
  .modl-cls:before, .modl-cls:after {
    content: '';
    position: absolute;
    background: #FEE400;
    height: 1px;
    width: 30px;
    top: 14px; }
  .modl-cls:before {
    transform: rotate(45deg); }
  .modl-cls:after {
    transform: rotate(-45deg); }

.modl-box {
  display: block;
  position: relative;
  z-index: 2;
  max-width: 700px;
  width: 100%;
  background: #FFFFFF;
  margin: 0 auto;
  max-height: calc(100vh - 120px);
  border-radius: 40px 0 40px 0;
  overflow: hidden; }
  .modl-box-in {
    overflow: auto;
    padding: 86px 101px;
    height: 100%;
    max-height: calc(100vh - 120px); }
  .modl-box-ttl {
    text-align: center;
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 18px; }
  .modl-box-help {
    text-align: center;
    font-size: 14px;
    margin-bottom: 35px;
    color: #E63327; }
  .modl-box-input {
    margin-bottom: 24px; }
    .modl-box-input input {
      border: 2px solid #FEE400;
      padding: 12px 21px;
      font-size: 14px;
      width: 100%; }
    .modl-box-input textarea {
      border: 2px solid #FEE400;
      padding: 12px 21px;
      font-size: 14px;
      width: 100%;
      height: 120px;
      resize: vertical; }
  .modl-box-btn button {
    font-size: 12px;
    padding: 10px 10px;
    width: 100%;
    max-width: 300px;
    margin: 0 auto; }
  .modl-box-info {
    margin-top: 10px; }
    .modl-box-info-logo {
      max-width: 39px;
      margin-bottom: 9px; }
    .modl-box-info-txt {
      font-size: 10px;
      margin-bottom: 7px; }
      .modl-box-info-txt br + br {
        display: none; }
    .modl-box-info-txt2 {
      font-size: 10px;
      color: #E63327; }
      .modl-box-info-txt2 br + br {
        display: none; }
  .modl-box-form {
    width: 100%; }
    .modl-box-form .wpcf7 form.invalid .wpcf7-response-output, .modl-box-form .wpcf7 form.unaccepted .wpcf7-response-output {
      display: none !important; }
    .modl-box-form .wpcf7-not-valid-tip {
      display: none !important; }
    .modl-box-form .wpcf7-not-valid {
      border-color: #E63327; }

/*modl*/
/*page_cont*/
.page_cont-info {
  padding: 90px 0 124px; }

.page_cont-ttl {
  margin-bottom: 67px; }

.page_cont-txt {
  font-size: 20px;
  line-height: 1.1;
  margin-bottom: 96px; }
  .page_cont-txt strong {
    font-weight: 700; }
  .page_cont-txt p {
    margin-bottom: 22px; }

.page_cont-mark {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 4px;
  max-width: 384px; }
  .page_cont-mark .soc {
    width: 100%;
    max-width: 90px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px; }
    .page_cont-mark .soc .ico {
      max-width: 25px;
      width: 100%; }
      .page_cont-mark .soc .ico.wa {
        max-width: 28px; }
  .page_cont-mark .txt {
    flex: 1;
    font-size: 18px;
    margin-bottom: 15px;
    max-width: 220px; }

.page_cont-phns {
  max-width: 406px;
  font-size: 20px;
  font-weight: 700; }
  .page_cont-phns a {
    display: inline-block;
    margin-bottom: 10px; }

.page_cont-img {
  margin-bottom: 45px; }

.page_cont-work {
  background: #f1f1f3;
  overflow: hidden;
  border-radius: 40px 0 40px 0;
  padding: 45px 20px 30px; }
  .page_cont-work-time {
    font-size: 18px;
    margin-bottom: 15px;
    padding-left: 50px;
    background: url("../img/contacts/time.svg") no-repeat 0% 0%;
    -webkit-background-size: 35px auto;
    background-size: 35px auto; }
    .page_cont-work-time .ttl {
      font-weight: 400; }
  .page_cont-work-alert {
    font-style: italic;
    line-height: 1.2;
    margin-bottom: 15px;
    padding-left: 50px;
    background: url("../img/contacts/keycard.svg") no-repeat 0% 0%;
    -webkit-background-size: 35px auto;
    background-size: 36px auto; }

/*page_cont*/
/*footer*/
.footer {
  background: #44413F;
  padding: 50px 0 10px; }

.f-logo {
  margin-bottom: 25px; }

.f-menu {
  max-width: 611px;
  margin: 40px auto 0px; }
  .f-menu ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px; }
    .f-menu ul li {
      padding: 0 15px;
      width: 100%;
      max-width: 196px;
      margin-bottom: 25px; }
      .f-menu ul li a {
        text-transform: uppercase;
        font-size: 16px;
        text-decoration: underline;
        color: #FFFFFF;
        font-weight: 400;
        letter-spacing: 1px; }
        .f-menu ul li a:hover {
          color: #FEE400; }

.f-info {
  text-align: right;
  font-weight: 400;
  font-size: 21px;
  padding-top: 38px;
  line-height: 1.2;
  margin-bottom: 14px;
  color: #FFFFFF; }
  .f-info span {
    display: block;
    margin-bottom: 11px; }
    .f-info span a:hover {
      color: #FEE400; }

.f-copy {
  font-size: 14px;
  color: #fff;
  line-height: 1.3;
  padding-bottom: 20px;
  opacity: 0.7; }

/*footer*/
.main_page-first {
  padding-bottom: 146px;
  background-color: #ffe401; }

.page_about-first {
  padding-bottom: 146px; }

.page_partn-first {
  padding-bottom: 146px; }

.page_cat-first {
  padding-bottom: 146px;
  background-position: 177px 0; }

.page_qual-first {
  padding-bottom: 146px; }

/*style fixes*/
.page_cat-info {
  padding: 100px 0; }

.page_partn-advant {
  padding-top: 136px; }

.page_qual-info {
  padding: 166px 0; }

.current_page_item a {
  color: #fee400; }

.btn:hover {
  box-shadow: 4px 5px 7px 0 rgba(0, 0, 0, 0.35) !important; }

/*style fixes*/
/*first*/
@media (min-width: 768px) and (max-width: 991px) {
  .h-slog {
    font-size: 20px; }
  .h-info {
    font-size: 12px; }
  .h-menu ul li {
    margin: 0 10px 15px; }
    .h-menu ul li a {
      font-size: 14px;
      white-space: nowrap; } }

@media (min-width: 768px) {
  .h-menu {
    display: block !important; } }

@media (max-width: 767px) {
  .h-menu {
    display: none; }
    .h-menu ul {
      flex-direction: column; }
      .h-menu ul li {
        text-align: center; } }

/*first*/
/*first*/
@media (min-width: 992px) and (max-width: 1200px) {
  .first-ttl {
    font-size: 30px;
    padding-top: 65px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .first {
    padding-bottom: 60px; }
    .first .first-ttl {
      font-size: 30px;
      padding-top: 50px; }
    .first .first-btn {
      margin-bottom: 30px; }
  .main_page-first {
    padding-bottom: 250px; } }

@media (max-width: 767px) {
  .first {
    padding-bottom: 60px;
    background-size: 100% auto;
    padding-top: 52vw; }
    .first-ttl {
      padding-top: 42px;
      font-size: 25px; }
    .first-img {
      display: none; }
  .main_page-first {
    padding-bottom: 60px; } }

/*first*/
/*repr*/
@media (min-width: 1200px) and (max-width: 1459px) {
  .repr-ttl {
    font-size: 18px; }
  .repr-txt {
    font-size: 14px; } }

@media (min-width: 992px) and (max-width: 1200px) {
  .repr-ttl {
    font-size: 16px; }
  .repr-txt {
    font-size: 15px; }
  .repr-wrap {
    padding: 45px 38px 37px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .repr {
    margin-top: -166px;
    margin-bottom: -166px; }
    .repr-img {
      text-align: center;
      transform: translateX(-13px); }
    .repr-wrap {
      padding: 45px 58px 37px; } }

@media (max-width: 767px) {
  .repr {
    margin: 0; }
    .repr-wrap {
      padding: 30px 0px;
      box-shadow: none;
      border-radius: 0; }
    .repr-ttl {
      font-size: 14px; }
    .repr-txt {
      font-size: 12px;
      margin-bottom: 10px; }
    .repr-img img {
      transform: translateX(-5px); } }

/*repr*/
/*third*/
@media (min-width: 1200px) and (max-width: 1459px) {
  .third-bl-txt {
    font-size: 20px; } }

@media (min-width: 992px) and (max-width: 1200px) {
  .third-bl-txt {
    font-size: 16px; } }

@media (max-width: 767px) {
  .third {
    padding-top: 60px;
    padding-bottom: 60px; }
    .third-ttl {
      margin-bottom: 30px; }
    .third-bl-txt {
      font-size: 18px; } }

/*third*/
/*main_products*/
@media (min-width: 1200px) and (max-width: 1459px) {
  .main_products-bl-btn {
    font-size: 14px; }
  .main_products-bl-img {
    margin-bottom: 30px; } }

@media (min-width: 992px) and (max-width: 1200px) {
  .main_products-txt {
    font-size: 16px; }
  .main_products-list .row {
    justify-content: center; }
  .main_products-col {
    max-width: 33%; } }

@media (max-width: 991px) {
  .main_products-list .row {
    justify-content: center; }
  .main_products-col {
    max-width: 50%;
    flex: unset; } }

@media (max-width: 767px) {
  .main_products {
    padding: 60px 0; } }

/*main_products*/
/*feat*/
@media (min-width: 1200px) and (max-width: 1459px) {
  .feat-detail-point {
    font-size: 16px; }
    .feat-detail-point.point-1 {
      top: 16%;
      left: 7.2%; }
    .feat-detail-point.point-2 {
      top: 2%;
      left: 40%; }
    .feat-detail-point.point-3 {
      left: 70.2%;
      top: -16%; }
    .feat-detail-point.point-4 {
      top: 62.7%;
      left: 75%; }
    .feat-detail-point.point-5 {
      top: 88.2%;
      left: 51.7%; }
  .feat-side {
    padding: 34px 29px 20px; }
    .feat-side ul li {
      font-size: 18px;
      margin-bottom: 20px; }
    .feat-side-ttl {
      font-size: 22px;
      margin-bottom: 29px; } }

@media (min-width: 992px) and (max-width: 1200px) {
  .feat-side {
    max-width: 444px;
    margin: 0 auto; } }

@media (max-width: 991px) {
  .feat-detail {
    display: flex;
    flex-wrap: wrap; }
    .feat-detail img {
      display: none; }
    .feat-detail-point {
      position: relative;
      padding-left: 25px;
      top: 0 !important;
      left: 0 !important;
      margin-bottom: 16px;
      width: 100%;
      max-width: 343px;
      padding-right: 20px; }
      .feat-detail-point .helpers {
        display: none; }
      .feat-detail-point:before {
        content: '';
        width: 5px;
        height: 5px;
        display: block;
        position: absolute;
        background: #f00;
        top: 7px;
        left: 0;
        border-radius: 50%; }
  .feat-side {
    margin-top: 29px; } }

@media (max-width: 767px) {
  .feat {
    padding: 60px 0 30px; }
    .feat-side {
      padding: 24px 26px; }
      .feat-side-ttl {
        font-size: 18px;
        margin-bottom: 24px; }
      .feat-side-list ul li {
        padding: 3px 0 3px 60px;
        font-size: 18px; } }

/*feat*/
/*pack*/
@media (min-width: 1200px) and (max-width: 1459px) {
  .pack-img-liner {
    width: 345px; }
  .pack-sublist-point {
    font-size: 15px; } }

@media (min-width: 992px) and (max-width: 1200px) {
  .pack-img-liner {
    width: 369px; }
  .pack-list {
    max-width: 420px; }
    .pack-list li {
      font-size: 17px; }
  .pack-sublist-point {
    font-size: 15px;
    margin-bottom: 16px; } }

@media (max-width: 991px) {
  .pack-img {
    position: relative; }
    .pack-img:before {
      display: none; }
    .pack-img-liner {
      position: static;
      max-width: 50%;
      margin-top: 40px; }
  .pack-sublist {
    margin-top: 32px; } }

@media (max-width: 767px) {
  .pack {
    padding-top: 52px;
    padding-bottom: 44px;
    background: #44413f; }
    .pack-sublist-point {
      font-size: 14px; }
    .pack-sublist-img {
      margin-right: 25px; } }

/*pack*/
/*brand*/
@media (max-width: 767px) {
  .brand {
    padding: 60px 0px; }
    .brand-sert-img {
      max-width: 199px;
      margin: 30px auto 16px; } }

/*brand*/
/*questions*/
@media (max-width: 767px) {
  .questions {
    padding: 60px 0; }
    .questions-ttl {
      margin-bottom: 28px; }
    .questions-input {
      flex: unset;
      width: 100%;
      margin-bottom: 20px; }
      .questions-input input {
        font-size: 16px;
        width: 100%;
        padding: 20px 20px; }
    .questions-form form {
      flex-wrap: wrap; }
    .questions-btn {
      max-width: none; }
      .questions-btn button {
        font-size: 16px;
        padding: 20px 20px; } }

/*questions*/
/*products*/
@media (min-width: 1200px) and (max-width: 1459px) {
  .products-bl-btn {
    font-size: 14px; }
  .products-bl-img {
    margin-bottom: 30px; } }

@media (min-width: 992px) and (max-width: 1200px) {
  .products-txt {
    font-size: 16px; }
  .products-list {
    justify-content: center; } }

@media (max-width: 991px) {
  .products-list {
    justify-content: center; } }

@media (max-width: 767px) {
  .products {
    padding: 60px 0; }
    .products-bl-img {
      margin-bottom: 20px; } }

/*products*/
/*page_about*/
@media (min-width: 1200px) and (max-width: 1459px) {
  .page_about-spec-point.point1 {
    top: 202px;
    left: -302px; }
  .page_about-spec-point.point2 {
    top: 255px;
    left: -232px; }
  .page_about-merits-bl {
    font-size: 16px; }
    .page_about-merits-bl img {
      height: 40px;
      margin-right: 10px; }
    .page_about-merits-bl .num {
      font-size: 28px; }
    .page_about-merits-bl .num_txt {
      font-size: 17px; }
  .page_about-act-list-bl-txt {
    font-size: 14px;
    font-weight: 400; }
  .page_about-act-list-bl-num {
    font-size: 55px; }
  .page_about-act-list-bl-ttl {
    font-size: 19px;
    flex: 1; } }

@media (min-width: 992px) and (max-width: 1200px) {
  .page_about-spec-point.point1 {
    top: 180px;
    left: -318px; }
  .page_about-spec-point.point2 {
    top: 218px;
    left: -268px; }
  .page_about-merits-bl {
    font-size: 12px; }
    .page_about-merits-bl img {
      height: 30px;
      margin-right: 10px; }
    .page_about-merits-bl .num {
      font-size: 20px; }
    .page_about-merits-bl .num_txt {
      font-size: 12px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .page_about-spec-point.point1 {
    top: 105%;
    left: 13px; }
    .page_about-spec-point.point1 .helpers .start:before {
      transform: rotate(-90deg);
      top: -87px;
      left: -86px;
      width: 182px; }
    .page_about-spec-point.point1 .helpers .end {
      left: 162px;
      top: -177px; }
      .page_about-spec-point.point1 .helpers .end:before {
        display: none; }
  .page_about-spec-point.point2 {
    top: 401px;
    left: 207px; }
    .page_about-spec-point.point2 .helpers .start:before {
      transform: rotate(-90deg);
      width: 75px;
      top: -33px;
      left: -33px; }
    .page_about-spec-point.point2 .helpers .end {
      top: -69px;
      left: 184px; }
      .page_about-spec-point.point2 .helpers .end:before {
        display: none; }
  .page_about-act-more {
    padding-top: 0; }
  .page_about-act-ttl {
    margin-bottom: 30px; }
  .page_about-merits-col {
    max-width: 33.3%;
    margin-bottom: 29px; }
    .page_about-merits-col.per_year {
      max-width: 33.3%; }
    .page_about-merits-col.merits_third {
      max-width: 33.3%;
      border-right: 0; }
  .page_about-merits-row {
    justify-content: center; }
  .page_about-merits-bl {
    font-size: 14px; }
    .page_about-merits-bl img {
      height: 40px;
      margin-right: 15px; }
    .page_about-merits-bl .num {
      font-size: 30px; }
    .page_about-merits-bl .num_txt {
      font-size: 18px; } }

@media (max-width: 767px) {
  .page_about-third {
    padding-top: 40px; }
    .page_about-third-txt {
      padding-right: 0; }
  .page_about-spec {
    padding: 30px 0; }
    .page_about-spec-txt {
      padding-bottom: 20px; }
    .page_about-spec-point {
      position: static;
      margin-top: 14px; }
      .page_about-spec-point .helpers {
        display: none; }
  .page_about-act {
    padding-top: 20px; }
    .page_about-act-more {
      padding-top: 0; }
    .page_about-act-ttl {
      margin-bottom: 25px; }
    .page_about-act-list-bl {
      padding: 33px 20px 35px; }
      .page_about-act-list-bl-txt {
        font-size: 14px; }
        .page_about-act-list-bl-txt br {
          display: none; }
      .page_about-act-list-bl-num {
        font-size: 40px;
        width: 27px; }
      .page_about-act-list-bl-ttl {
        flex: 1;
        font-size: 18px;
        padding-top: 6px; }
        .page_about-act-list-bl-ttl br {
          display: none; }
      .page_about-act-list-bl-top {
        align-items: center; }
  .page_about-merits {
    padding: 76px 0 0px; }
    .page_about-merits-col {
      border-right: 0 !important;
      max-width: 290px !important; }
      .page_about-merits-col:last-child .page_about-merits-bl {
        border-bottom: 0 !important; }
    .page_about-merits-bl {
      font-size: 15px;
      border-bottom: 3px solid #f1f1f3;
      padding-bottom: 25px;
      justify-content: center;
      text-align: center; }
      .page_about-merits-bl span {
        flex: unset; }
        .page_about-merits-bl span.mob-tal {
          text-align: left; }
    .page_about-merits-row {
      justify-content: center; }
  .page_about-prod {
    padding-bottom: 20px; }
  .page_about-qual {
    padding-top: 30px; }
    .page_about-qual-list-point {
      font-size: 16px; } }

/*page_about*/
/*page_cont*/
@media (min-width: 1200px) and (max-width: 1459px) {
  .page_cont-work-time {
    font-size: 13px; }
  .page_cont-work-alert {
    font-size: 13px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .page_cont-txt {
    margin-bottom: 30px; }
  .page_cont-phns {
    max-width: 100%;
    margin-bottom: 30px; }
  .page_cont-mark {
    max-width: 100%; } }

@media (max-width: 767px) {
  .page_cont-txt {
    margin-bottom: 30px; }
  .page_cont-work-time {
    font-size: 14px; }
  .page_cont-info {
    padding-bottom: 50px; }
  .page_cont-phns {
    margin-bottom: 30px; }
  .page_cont-info {
    padding-top: 30px; }
  .page_cont-ttl {
    margin-bottom: 30px; } }

/*page_cont*/
/*page_cat*/
/*page_cont*/
@media (max-width: 767px) {
  .page_cat-info {
    padding-top: 0; }
    .page_cat-info-ttl {
      margin-bottom: 20px; } }

/*page_cat*/
/*page_partn*/
@media (min-width: 1200px) and (max-width: 1459px) {
  .page_partn-advant-bl-ttl {
    font-size: 13px; }
  .page_partn-advant-bl-txt {
    font-size: 11px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .page_partn-cont {
    padding-bottom: 45px; }
    .page_partn-cont-phns {
      max-width: 100%;
      margin-bottom: 30px; }
    .page_partn-cont-mark {
      max-width: 100%; } }

@media (max-width: 767px) {
  .page_partn-cont {
    padding-bottom: 45px; }
    .page_partn-cont-phns {
      max-width: 100%;
      margin-bottom: 30px; }
    .page_partn-cont-mark {
      max-width: 100%; }
    .page_partn-cont-list-point br {
      display: none; }
  .page_partn-boxes {
    overflow: hidden; }
    .page_partn-boxes img {
      width: 173vw;
      max-width: none;
      transform: translateX(-20%); }
  .page_partn-advant-bl {
    margin-bottom: 40px; }
    .page_partn-advant-bl-logo {
      margin-bottom: 5px; }
    .page_partn-advant-bl_wrap:last-child .page_partn-advant-bl {
      margin-bottom: 0; } }

/*page_partn*/
/*page_qual*/
@media (min-width: 1200px) and (max-width: 1459px) {
  .page_qual-pack-img-liner {
    width: 345px; }
  .page_qual-pack-sublist-point {
    font-size: 15px; } }

@media (min-width: 992px) and (max-width: 1200px) {
  .page_qual-pack-img-liner {
    width: 369px; }
  .page_qual-pack-list {
    max-width: 420px; }
    .page_qual-pack-list li {
      font-size: 17px; }
  .page_qual-pack-sublist-point {
    font-size: 15px;
    margin-bottom: 16px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .page_qual-info {
    padding-bottom: 50px;
    padding-top: 50px; }
    .page_qual-info-note {
      margin-bottom: 40px; }
  .page_qual-pack {
    padding: 60px 0; } }

@media (max-width: 991px) {
  .page_qual-pack-img {
    position: relative; }
    .page_qual-pack-img:before {
      display: none; }
    .page_qual-pack-img-liner {
      position: static;
      max-width: 50%;
      margin-top: 40px; }
  .page_qual-pack-sublist {
    margin-top: 32px; }
  .page_qual-pack-reg {
    margin-bottom: 40px; } }

@media (max-width: 767px) {
  .page_qual-info {
    padding-bottom: 50px;
    padding-top: 50px; }
    .page_qual-info-note {
      margin-bottom: 40px; }
  .page_qual-pack {
    padding-top: 112px;
    padding-bottom: 60px; }
    .page_qual-pack-sublist-point {
      font-size: 14px; }
    .page_qual-pack-sublist-img {
      margin-right: 25px; } }

/*page_qual*/
/*prod*/
@media (min-width: 1200px) and (max-width: 1459px) {
  .prod-thumbs {
    margin-right: 30px; }
  .prod-thumb-img {
    margin-bottom: 40px; }
  .prod-slider {
    max-width: 403px; } }

@media (min-width: 992px) and (max-width: 1200px) {
  .prod-info {
    padding-left: 0; }
  .prod-ttl {
    font-size: 26px;
    margin-bottom: 30px; }
  .prod-thumbs {
    max-width: 80px;
    margin-right: 15px; }
  .prod-slider {
    max-width: 354px; } }

@media (max-width: 991px) {
  .prod-info {
    padding-left: 0; } }

@media (max-width: 767px) {
  .prod {
    padding-top: 30px; }
    .prod-back {
      margin-bottom: 30px; }
    .prod-thumbs {
      display: none; }
    .prod-ttl {
      font-size: 24px;
      margin-bottom: 25px; }
    .prod-price {
      font-size: 24px;
      margin-bottom: 45px; }
    .prod-tabs {
      margin-top: 0; }
    .prod-rel {
      padding-top: 36px; }
    .prod .products-list {
      padding-top: 21px; }
    .prod-rel {
      padding-bottom: 30px; }
    .prod-tabs a {
      margin-right: 10px; }
    .prod-chars-row {
      margin: 0 -15px; }
      .prod-chars-row .char, .prod-chars-row .value {
        padding: 11px 15px;
        font-size: 12px; } }

/*prod*/
/*footer*/
@media (min-width: 1200px) and (max-width: 1459px) {
  .f-menu ul li {
    max-width: 190px; } }

@media (min-width: 992px) and (max-width: 1200px) {
  .f-info {
    font-size: 17px; } }

@media (max-width: 767px) {
  .f-logo {
    text-align: center;
    max-width: 200px;
    margin: 0 auto; }
  .f-menu ul li {
    text-align: center;
    max-width: 100%; }
  .f-info {
    padding-top: 10px;
    text-align: center; } }

/*footer*/
@media (min-width: 1200px) {
  .page_about-first {
    background-position: 26% 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .main_page-first {
    height: 556px; }
  .page_about-first {
    height: 556px;
    background-position: 26% 0; }
  .page_partn-first {
    height: 556px; }
  .page_cat-first {
    height: 556px;
    background-position: 26% 0; }
  .page_qual-first {
    height: 556px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .main_page-first {
    background-position: 32% 0; }
  .page_about-first {
    background-position: 32% 0;
    padding-bottom: 223px; }
  .page_partn-first {
    background-position: 32% 0; }
  .page_cat-first {
    background-position: 32% 0; }
  .page_qual-first {
    background-position: 32% 0; } }

@media (max-width: 767px) {
  .main_page-first {
    background-position: 0 0; }
  .page_about-first {
    background-position: 0 0; }
  .page_partn-first {
    background-position: 0 0; }
  .page_cat-first {
    background-position: 0 0; }
  .page_qual-first {
    background-position: 0 0; } }

/*style fixes*/
@media (min-width: 992px) {
  .main_page-first, .page_about-first, .page_partn-first, .page_cat-first, .page_qual-first {
    height: 556px; } }

@media (min-width: 1199px) {
  .page_cat-first {
    background-position: 50% 0; } }

@media (min-width: 768px) and (max-width: 1650px) {
  .repr {
    margin-top: -73px; } }

@media (min-width: 768px) and (max-width: 1460px) {
  .first-ttl {
    font-size: 32px; } }

/*style fixes*/
