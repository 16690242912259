/*first*/
@media(min-width: 768px) and (max-width: 991px) {
    .h {
        &-slog {
            font-size: 20px; }
        &-info {
            font-size: 12px; }
        &-menu {
            ul {
                li {
                    margin: 0 10px 15px;
                    a {
                        font-size: 14px;
                        white-space: nowrap; } } } } } }

@media(min-width: 768px) {
    .h {
        &-menu {
            display: block !important; } } }

@media(max-width: 767px) {
    .h {
        &-menu {
            display: none;
            ul {
                flex-direction: column;
                li {
                    text-align: center; } } } } }

/*first*/

/*first*/
@media(min-width: 992px) and (max-width: 1200px) {
    .first {
        &-ttl {
            font-size: 30px;
            padding-top: 65px; } } }

@media(min-width: 768px) and (max-width: 991px) {
    .first {
        padding-bottom: 60px;
        .first-ttl {
            font-size: 30px;
            padding-top: 50px; }
        .first-btn {
            margin-bottom: 30px; } }
    .main_page-first {
        padding-bottom: 250px; } }

@media(max-width: 767px) {
    .first {
        padding-bottom: 60px;
        background-size: 100% auto;
        padding-top: 52vw;
        &-ttl {
            padding-top: 42px;
            font-size: 25px; }
        &-img {
            display: none; } }
    .main_page-first {
        padding-bottom: 60px; } }
/*first*/

/*repr*/
@media(min-width: 1200px) and (max-width: 1459px) {
    .repr {
        &-ttl {
            font-size: 18px; }
        &-txt {
            font-size: 14px; } } }

@media(min-width: 992px) and (max-width: 1200px) {
    .repr {
        &-ttl {
            font-size: 16px; }
        &-txt {
            font-size: 15px; }
        &-wrap {
            padding: 45px 38px 37px; } } }

@media(min-width: 768px) and (max-width: 991px) {
    .repr {
        margin-top: -166px;
        margin-bottom: -166px;
        &-img {
            text-align: center;
            transform: translateX(-13px); }
        &-wrap {
            padding: 45px 58px 37px; } } }

@media(max-width: 767px) {
    .repr {
        margin: 0;
        &-wrap {
            padding: 30px 0px;
            box-shadow: none;
            border-radius: 0; }
        &-ttl {
            font-size: 14px; }
        &-txt {
            font-size: 12px;
            margin-bottom: 10px; }
        &-img {
            img {
                transform: translateX(-5px); } } } }

/*repr*/

/*third*/
@media(min-width: 1200px) and (max-width: 1459px) {
    .third {
        &-bl {
            &-txt {
                font-size: 20px; } } } }

@media(min-width: 992px) and (max-width: 1200px) {
    .third {
        &-bl {
            &-txt {
                font-size: 16px; } } } }

@media(max-width: 767px) {
    .third {
        padding-top: 60px;
        padding-bottom: 60px;
        &-ttl {
            margin-bottom: 30px; }
        &-bl {
            &-txt {
                font-size: 18px; } } } }

/*third*/

/*main_products*/
@media(min-width: 1200px) and (max-width: 1459px) {
    .main_products {
        &-bl {
            &-btn {
                font-size: 14px; }
            &-img {
                margin-bottom: 30px; } } } }

@media(min-width: 992px) and (max-width: 1200px) {
    .main_products {
        &-txt {
            font-size: 16px; }
        &-list {
            .row {
                justify-content: center; } }
        &-col {
            max-width: 33%; } } }
@media(max-width: 991px) {
    .main_products {
        &-list {
            .row {
                justify-content: center; } }
        &-col {
            max-width: 50%;
            flex: unset; } } }

@media(max-width: 767px) {
    .main_products {
        padding: 60px 0; } }
/*main_products*/

/*feat*/
@media(min-width: 1200px) and (max-width: 1459px) {
    .feat {
        &-detail {
            &-point {
                font-size: 16px;
                &.point-1 {
                    top: 16%;
                    left: 7.2%; }
                &.point-2 {
                    top: 2%;
                    left: 40%; }
                &.point-3 {
                    left: 70.2%;
                    top: -16%; }
                &.point-4 {
                    top: 62.7%;
                    left: 75%; }
                &.point-5 {
                    top: 88.2%;
                    left: 51.7%; } } }
        &-side {
            padding: 34px 29px 20px;
            ul {
                li {
                    font-size: 18px;
                    margin-bottom: 20px; } }
            &-ttl {
                font-size: 22px;
                margin-bottom: 29px; } } } }

@media(min-width: 992px) and (max-width: 1200px) {
    .feat {
        &-side {
            max-width: 444px;
            margin: 0 auto; } } }
@media(max-width: 991px) {
    .feat {
        &-detail {
            display: flex;
            flex-wrap: wrap;
            img {
                display: none; }
            &-point {
                position: relative;
                padding-left: 25px;
                top: 0!important;
                left: 0!important;
                margin-bottom: 16px;
                width: 100%;
                max-width: 343px;
                padding-right: 20px;
                .helpers {
                    display: none; }
                &:before {
                    content: '';
                    width: 5px;
                    height: 5px;
                    display: block;
                    position: absolute;
                    background: #f00;
                    top: 7px;
                    left: 0;
                    border-radius: 50%; } } }
        &-side {
            margin-top: 29px; } } }

@media(max-width: 767px) {
    .feat {
        padding: 60px 0 30px;
        &-side {
            padding: 24px 26px;
            &-ttl {
                font-size: 18px;
                margin-bottom: 24px; }
            &-list {
                ul {
                    li {
                        padding: 3px 0 3px 60px;
                        font-size: 18px; } } } } } }


/*feat*/


/*pack*/
@media(min-width: 1200px) and (max-width: 1459px) {
    .pack {
        &-img {
            &-liner {
                width: 345px; } }
        &-sublist {
            &-point {
                font-size: 15px; } } } }

@media(min-width: 992px) and (max-width: 1200px) {
    .pack {
        &-img {
            &-liner {
                width: 369px; } }
        &-list {
            max-width: 420px;
            li {
                font-size: 17px; } }
        &-sublist {
            &-point {
                font-size: 15px;
                margin-bottom: 16px; } } } }

@media(max-width: 991px) {
    .pack {
        &-img {
            position: relative;
            &:before {
                display: none; }
            &-liner {
                position: static;
                max-width: 50%;
                margin-top: 40px; } }
        &-sublist {
            margin-top: 32px; } } }

@media(max-width: 767px) {
    .pack {
        padding-top: 52px;
        padding-bottom: 44px;
        background: #44413f;
        &-sublist {
            &-point {
                font-size: 14px; }
            &-img {
                margin-right: 25px; } } } }
/*pack*/

/*brand*/
@media(max-width: 767px) {
    .brand {
        padding: 60px 0px;
        &-sert {
            &-img {
                max-width: 199px;
                margin: 30px auto 16px; } } } }

/*brand*/

/*questions*/
@media(max-width: 767px) {
    .questions {
        padding: 60px 0;
        &-ttl {
            margin-bottom: 28px; }
        &-input {
            flex: unset;
            width: 100%;
            margin-bottom: 20px;
            input {
                font-size: 16px;
                width: 100%;
                padding: 20px 20px; } }
        &-form {
            form {
                flex-wrap: wrap; } }
        &-btn {
            max-width: none;
            button {
                font-size: 16px;
                padding: 20px 20px; } } } }

/*questions*/

/*products*/
@media(min-width: 1200px) and (max-width: 1459px) {
    .products {
        &-bl {
            &-btn {
                font-size: 14px; }
            &-img {
                margin-bottom: 30px; } } } }

@media(min-width: 992px) and (max-width: 1200px) {
    .products {
        &-txt {
            font-size: 16px; }
        &-list {
            .row {}
            justify-content: center; } } }
@media(max-width: 991px) {
    .products {
        &-list {
            .row {}
            justify-content: center; } } }

@media(max-width: 767px) {
    .products {
        padding: 60px 0;
        &-bl {
            &-img {
                margin-bottom: 20px; } } } }
/*products*/

/*page_about*/

@media(min-width: 1200px) and (max-width: 1459px) {
    .page_about {
        &-spec {
            &-point {
                &.point1 {
                    top: 202px;
                    left: -302px; }
                &.point2 {
                    top: 255px;
                    left: -232px; } } }

        &-merits {
            &-bl {
                font-size: 16px;
                img {
                    height: 40px;
                    margin-right: 10px; }
                .num {
                    font-size: 28px; }
                .num_txt {
                    font-size: 17px; } } }
        &-act {
            &-list {
                &-bl {
                    &-txt {
                        font-size: 14px;
                        font-weight: 400; }
                    &-num {
                        font-size: 55px; }
                    &-ttl {
                        font-size: 19px;
                        flex: 1; } } } } } }

@media(min-width: 992px) and (max-width: 1200px) {
    .page_about {
        &-spec {
            &-point {
                &.point1 {
                    top: 180px;
                    left: -318px; }
                &.point2 {
                    top: 218px;
                    left: -268px; } } }
        &-merits {
            &-bl {
                font-size: 12px;
                img {
                    height: 30px;
                    margin-right: 10px; }
                .num {
                    font-size: 20px; }
                .num_txt {
                    font-size: 12px; } } } } }

@media(min-width: 768px) and (max-width: 991px) {
    .page_about {
        &-spec {
            &-point {
                &.point1 {
                    top: 105%;
                    left: 13px;
                    .helpers {
                        .start {
                            &:before {
                                transform: rotate(-90deg);
                                top: -87px;
                                left: -86px;
                                width: 182px; } }
                        .end {
                            left: 162px;
                            top: -177px;
                            &:before {
                                display: none; } } } }
                &.point2 {
                    top: 401px;
                    left: 207px;
                    .helpers {
                        .start {
                            &:before {
                                transform: rotate(-90deg);
                                width: 75px;
                                top: -33px;
                                left: -33px; } }
                        .end {
                            top: -69px;
                            left: 184px;
                            &:before {
                                display: none; } } } } } }
        &-act {
            &-more {
                padding-top: 0; }
            &-ttl {
                margin-bottom: 30px; } }
        &-merits {
            &-col {
                max-width: 33.3%;
                margin-bottom: 29px;
                &.per_year {
                    max-width: 33.3%; }
                &.merits_third {
                    max-width: 33.3%;
                    border-right: 0; } }
            &-row {
                justify-content: center; }
            &-bl {
                font-size: 14px;
                img {
                    height: 40px;
                    margin-right: 15px; }
                .num {
                    font-size: 30px; }
                .num_txt {
                    font-size: 18px; } } } } }

@media(max-width: 767px) {
    .page_about {
        &-third {
            padding-top: 40px;
            &-txt {
                padding-right: 0; } }
        &-spec {
            padding: 30px 0;
            &-txt {
                padding-bottom: 20px; }
            &-point {
                position: static;
                margin-top: 14px;
                .helpers {
                    display: none; } } }
        &-act {
            padding-top: 20px;
            &-more {
                padding-top: 0; }
            &-ttl {
                margin-bottom: 25px; }
            &-list {
                &-bl {
                    padding: 33px 20px 35px;
                    &-txt {
                        font-size: 14px;
                        br {
                            display: none; } }
                    &-num {
                        font-size: 40px;
                        width: 27px; }
                    &-ttl {
                        flex: 1;
                        font-size: 18px;
                        padding-top: 6px;
                        br {
                            display: none; } }
                    &-top {
                        align-items: center; } } } }
        &-merits {
            padding: 76px 0 0px;
            &-col {
                border-right: 0!important;
                max-width: 290px!important;
                &:last-child {
                    .page_about-merits-bl {
                        border-bottom: 0!important; } } }
            &-bl {
                font-size: 15px;
                border-bottom: 3px solid #f1f1f3;
                padding-bottom: 25px;
                justify-content: center;
                text-align: center;
                span {
                    flex: unset;
                    &.mob-tal {
                        text-align: left; } } }
            &-row {
                justify-content: center; } }
        &-prod {
            padding-bottom: 20px; }
        &-qual {
            padding-top: 30px;
            &-list {
                &-point {
                    font-size: 16px; } } } } }
/*page_about*/

/*page_cont*/
@media(min-width: 1200px) and (max-width: 1459px) {
    .page_cont {
        &-work {
            &-time {
                font-size: 13px; }
            &-alert {
                font-size: 13px; } } } }

@media(min-width: 768px) and (max-width: 991px) {
    .page_cont {
        &-txt {
            margin-bottom: 30px; }
        &-phns {
            max-width: 100%;
            margin-bottom: 30px; }
        &-mark {
            max-width: 100%; } } }

@media(max-width: 767px) {
    .page_cont {
        &-txt {
            margin-bottom: 30px; }
        &-work {
            &-time {
                font-size: 14px; } }
        &-info {
            padding-bottom: 50px; }

        &-phns {
            margin-bottom: 30px; }
        &-info {
            padding-top: 30px; }
        &-ttl {
            margin-bottom: 30px; } } }
/*page_cont*/

/*page_cat*/

/*page_cont*/
@media(max-width: 767px) {
    .page_cat {
        &-info {
            padding-top: 0;
            &-ttl {
                margin-bottom: 20px; } } } }
/*page_cat*/

/*page_partn*/
@media(min-width: 1200px) and (max-width: 1459px) {
    .page_partn {
        &-advant {
            &-bl {
                &-ttl {
                    font-size: 13px; }
                &-txt {
                    font-size: 11px; } } } } }

@media(min-width: 992px) and (max-width: 1200px) {
    .page_partn {} }

@media(min-width: 768px) and (max-width: 991px) {
    .page_partn {
        &-cont {
            padding-bottom: 45px;
            &-phns {
                max-width: 100%;
                margin-bottom: 30px; }
            &-mark {
                max-width: 100%; } } } }

@media(max-width: 767px) {
    .page_partn {
        &-cont {
            padding-bottom: 45px;
            &-phns {
                max-width: 100%;
                margin-bottom: 30px; }
            &-mark {
                max-width: 100%; }
            &-list {
                &-point {
                    br {
                        display: none; } } } }
        &-boxes {
            overflow: hidden;
            img {
                width: 173vw;
                max-width: none;
                transform: translateX(-20%); } }
        &-advant {
            &-bl {
                margin-bottom: 40px;
                &-logo {
                    margin-bottom: 5px; }
                &_wrap {
                    &:last-child {
                        .page_partn-advant-bl {
                            margin-bottom: 0; } } } } } } }
/*page_partn*/

/*page_qual*/
@media(min-width: 1200px) and (max-width: 1459px) {
    .page_qual {
        &-pack {
            &-img {
                &-liner {
                    width: 345px; } }
            &-sublist {
                &-point {
                    font-size: 15px; } } } } }

@media(min-width: 992px) and (max-width: 1200px) {
    .page_qual {
        &-pack {
            &-img {
                &-liner {
                    width: 369px; } }
            &-list {
                max-width: 420px;
                li {
                    font-size: 17px; } }
            &-sublist {
                &-point {
                    font-size: 15px;
                    margin-bottom: 16px; } } } } }

@media(min-width: 768px) and (max-width: 991px) {
    .page_qual {
        &-info {
            padding-bottom: 50px;
            padding-top: 50px;
            &-note {
                margin-bottom: 40px; } }
        &-pack {
            padding: 60px 0; } } }
@media(max-width: 991px) {
    .page_qual {
        &-pack {
            &-img {
                position: relative;
                &:before {
                    display: none; }
                &-liner {
                    position: static;
                    max-width: 50%;
                    margin-top: 40px; } }
            &-sublist {
                margin-top: 32px; }
            &-reg {
                margin-bottom: 40px; } } } }

@media(max-width: 767px) {
    .page_qual {
        &-info {
            padding-bottom: 50px;
            padding-top: 50px;
            &-note {
                margin-bottom: 40px; } }
        &-pack {
            padding-top: 112px;
            padding-bottom: 60px;
            &-sublist {
                &-point {
                    font-size: 14px; }
                &-img {
                    margin-right: 25px; } } } } }
/*page_qual*/

/*prod*/
@media(min-width: 1200px) and (max-width: 1459px) {
    .prod {
        &-thumbs {
            margin-right: 30px; }
        &-thumb {
            &-img {
                margin-bottom: 40px; } }
        &-slider {
            max-width: 403px; } } }

@media(min-width: 992px) and (max-width: 1200px) {
    .prod {
        &-info {
            padding-left: 0; }
        &-ttl {
            font-size: 26px;
            margin-bottom: 30px; }
        &-thumbs {
            max-width: 80px;
            margin-right: 15px; }
        &-slider {
            max-width: 354px; } } }

@media(max-width: 991px) {
    .prod {
        &-info {
            padding-left: 0; } } }

@media(max-width: 767px) {
    .prod {
        padding-top: 30px;
        &-back {
            margin-bottom: 30px; }
        &-thumbs {
            display: none; }
        &-ttl {
            font-size: 24px;
            margin-bottom: 25px; }
        &-price {
            font-size: 24px;
            margin-bottom: 45px; }
        &-tabs {
            margin-top: 0; }
        &-rel {
            padding-top: 36px; }
        .products-list {
            padding-top: 21px; }
        &-rel {
            padding-bottom: 30px; }
        &-tabs {
            a {
                margin-right: 10px; } }
        &-chars {
            &-row {
                margin: 0 -15px;
                .char, .value {
                    padding: 11px 15px;
                    font-size: 12px; } } } } }

/*prod*/

/*footer*/
@media(min-width: 1200px) and (max-width: 1459px) {
    .f {
        &-menu {
            ul {
                li {
                    max-width: 190px; } } } } }

@media(min-width: 992px) and (max-width: 1200px) {
    .f {
        &-info {
            font-size: 17px; } } }

@media(max-width: 767px) {
    .f {
        &-logo {
            text-align: center;
            max-width: 200px;
            margin: 0 auto; }
        &-menu {
            ul {
                li {
                    text-align: center;
                    max-width: 100%; } } }
        &-info {
            padding-top: 10px;
            text-align: center; } } }

/*footer*/

@media(min-width: 1200px) {
    .page_about-first {
        background-position: 26% 0; } }

@media(min-width: 992px) and (max-width: 1199px) {
    .main_page-first {
        height: 556px; }
    .page_about-first {
        height: 556px;
        background-position: 26% 0; }
    .page_partn-first {
        height: 556px; }
    .page_cat-first {
        height: 556px;
        background-position: 26% 0; }
    .page_qual-first {
        height: 556px; } }

@media(min-width: 768px) and (max-width: 991px) {
    .main_page-first {
        background-position: 32% 0; }
    .page_about-first {
        background-position: 32% 0;
        padding-bottom: 223px; }
    .page_partn-first {
        background-position: 32% 0; }
    .page_cat-first {
        background-position: 32% 0; }
    .page_qual-first {
        background-position: 32% 0; } }

@media(max-width: 767px) {
    .main_page-first {
        background-position: 0 0; }
    .page_about-first {
        background-position: 0 0; }
    .page_partn-first {
        background-position: 0 0; }
    .page_cat-first {
        background-position: 0 0; }
    .page_qual-first {
        background-position: 0 0; } }


/*style fixes*/
@media(min-width: 992px) {
    .main_page-first, .page_about-first, .page_partn-first, .page_cat-first, .page_qual-first {
        height: 556px; } }


@media(min-width: 1199px) {
    .page_cat-first {
        background-position: 50% 0; } }

@media(min-width: 768px) and (max-width: 1650px) {
    .repr {
        margin-top: -73px; } }

@media(min-width: 768px) and (max-width: 1460px) {
    .first-ttl {
        font-size: 32px; } }

/*style fixes*/
